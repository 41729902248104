export class ClientEnvironmentMH {
  //readonly splunk: boolean;
  readonly name: "development" | "production" | "testing" | "staging";
  readonly graphServerUrl: string;
  readonly graphServerSubscriptionUrl: string;
  readonly cors: string;
  readonly cookieDomain: string;

  readonly imageUrl: string;
  readonly fileUrl: string;
  readonly serverUrl: string;
  readonly legacyUrl: string;
  readonly s3UriUploadEnabled: boolean;

  constructor() {
    // Environment
    switch (this.resolveValue("REACT_APP_ENVIR")) {
      case "development":
        this.name = "development";
        break;
      case "testing":
        this.name = "testing";
        break;
      case "staging":
        this.name = "staging";
        break;
      default:
        this.name = "production";
        break;
    }

    this.graphServerUrl = this.handleRequired("REACT_APP_GRAPH_SERVER");
    this.graphServerSubscriptionUrl = this.handleRequired("REACT_APP_GRAPH_SERVER_SUBSCRIPTION");

    this.cors = this.resolveValue("REACT_APP_CORS", "");
    this.cookieDomain = this.resolveValue("REACT_APP_COOKIE_DOMAIN", "");

    this.imageUrl = this.resolveValue("REACT_APP_IMAGE_URL", "");
    this.fileUrl = this.resolveValue("REACT_APP_FILE_URL", "");
    this.serverUrl = this.resolveValue("REACT_APP_SERVER_URL", "");
    this.legacyUrl = this.resolveValue("REACT_APP_LEGACY_URL", "");
    this.s3UriUploadEnabled = this.resolveValue("REACT_APP_S3_URI_UPLOAD", "false") === "true";

    this.name !== "production" && this.log();
  }

  private log() {
    console.log(`\n### ${this.name}-Environment #############################################\n#`);
    console.log(`#  Graph-Server    :   `, this.graphServerUrl);
    console.log(`#  Graph-Server-Sub:   `, this.graphServerSubscriptionUrl);
    console.log(`#  Server-Url      :   `, this.serverUrl);
    console.log(`#  Image-Url       :   `, this.imageUrl);
    console.log(`#  File-Url        :   `, this.fileUrl);
    console.log(`#  Legacy-Url      :   `, this.legacyUrl);
    console.log(`#  CORS            :   `, this.cors);
    console.log(`#  UriUpload       :   `, this.s3UriUploadEnabled);
    console.log("#########################################################################\n");
  }

  private handleRequiredMissing(name: string): never {
    throw new Error(`Parameter <${name}> not set. Please adjust '.env' or 'process.env'`);
  }

  protected handleRequired(name: Extract<keyof typeof process.env, string>, value?: string): string {
    if (!value) value = this.resolveValue(name);
    if (!value) return this.handleRequiredMissing(name);
    else return value;
  }

  protected resolveValue<T = string | undefined>(name: string, defaultValue?: T): ValueType<T, string> {
    let val = process.env[name];
    if (val) {
      return val as ValueType<T, string>;
    } else return defaultValue as ValueType<T, string>;
  }
}

type ValueType<T, V> = T extends undefined ? V | undefined : V;

export const environment = new ClientEnvironmentMH();
