import * as React from "react";
import {
  BlockScreenInfo,
  ButtonInfo,
  NotificationDispatcher,
  NotificationKind,
  NotificationStyle,
  ProgressHandle
} from "../core/NotificationService";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Paper, { PaperProps } from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { WaitIndicatorMH } from "./WaitIndicatorMH";
import { isResourceKeyReference, NotificationService, useLocalize, useServices } from "@emibee/lib-app-common";
// import Draggable from "react-draggable";
import { makeStyles } from "../Theme";

const useProgressStyles = makeStyles()(theme => ({
  root: {
    position: "relative"
  },
  message: {
    verticalAlign: "middle",
    marginLeft: theme.spacing(1)
  },
  progress: {
    position: "absolute",
    top: 2,
    left: 0,
    width: 22,
    textAlign: "center"
  }
}));

const useBlockScreenStyles = makeStyles()(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  pending: {
    position: "absolute",
    top: "20%"
  },
  paper: {
    zIndex: 1,
    position: "relative",
    padding: theme.spacing(4),
    maxWidth: 600
  },

  contentText: {
    marginLeft: theme.spacing(2) + 36
  },
  title: {
    padding: 0
  },
  footer: {
    flexGrow: 100,
    paddingLeft: 36 + theme.spacing(1)
  },
  icon: {
    color: "#ffa000"
  }

  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
}));

const useDialogStyles = makeStyles()(theme => ({
  titleText: {
    marginLeft: theme.spacing(2),
    verticalAlign: "text-bottom"
  },
  footer: {
    flexGrow: 100,
    paddingLeft: theme.spacing(2)
  }
  //   icon: {
  //     color: "#ffa000",
  //   }

  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: '2px solid #000',
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
}));

interface ProgressIndicatorProps {
  message?: string | React.ReactNode;
  progress?: number;
  progressHandle?: ProgressHandle;
}

interface CustomDialogReg {
  id?: string | number;
  dialog: React.ReactElement;
}

function ProgressIndicator(props: ProgressIndicatorProps) {
  const { classes } = useProgressStyles();
  const [progress, setProgress] = React.useState(props.progress);

  React.useEffect(() => {
    if (props.progressHandle) {
      props.progressHandle.update = setProgress;
    }
  }, [props.progressHandle, setProgress]);

  return (
    <div className={classes.root}>
      <CircularProgress style={{ width: 22, height: 22, verticalAlign: "middle" }} />
      <span className={classes.progress}>{progress}</span>
      <span className={classes.message}>{props.message}</span>
    </div>
  );
}

export function NotificationProvider() {
  console.log("NotificationProvider.Render");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [customDialog, setCustomDialog] = React.useState<CustomDialogReg[]>();
  const [dialogInfo, setDialogInfo] = React.useState<DialogInfo>();
  const [blockScreenInfo, setBlockScreenInfo] = React.useState<BlockScreenProps>();
  const accessor = useServices();
  const localize = useLocalize();
  React.useEffect(() => {
    const service = accessor.get(NotificationService) as NotificationDispatcher;
    service.registerNotificationProvider(args => {
      const { message, persist, closeHandle, progress, title, style, buttons, customElement, dialogId } = args;
      if (style === NotificationStyle.BlockScreen) {
        setBlockScreenInfo({ open: true });
        return (info: BlockScreenInfo) => {
          if (info.query) {
            let blockProps: BlockScreenProps | undefined;
            const promise = new Promise<number>(resolve => {
              blockProps = {
                ...info,
                decisionCallback: resolve
              };
            });
            setBlockScreenInfo(blockProps);
            return promise.then(val => {
              !val && setBlockScreenInfo(undefined);
              return val;
            });
          } else {
            setBlockScreenInfo(info);
          }
        };
      } else if (style === NotificationStyle.Dialog) {
        let dialogInfo: DialogInfo | undefined = undefined;
        const promise = new Promise<number>(resolve => {
          dialogInfo = {
            title: title!,
            message: message!,
            buttons: buttons!,
            decisionCallback: resolve
          };
        });
        setDialogInfo(dialogInfo);
        return promise.then(val => {
          setDialogInfo(undefined);
          return val;
        });
      } else if (style === NotificationStyle.Custom) {
        if (customElement) {
          const dlg: CustomDialogReg = {
            id: dialogId,
            dialog: customElement
          };
          setCustomDialog(list => (list ? [...list, dlg] : [dlg]));
        } else {
          setCustomDialog(list => (list ? list.filter(reg => reg.id !== dialogId) : undefined));
        }
      } else {
        let msgNode: React.ReactNode = isResourceKeyReference(message) ? localize(message) : message;
        let kind: string = "default";
        switch (args.kind) {
          case NotificationKind.success:
            kind = "success";
            break;
          case NotificationKind.error:
            kind = "error";
            break;
          case NotificationKind.warning:
            kind = "warning";
            break;
          case NotificationKind.info:
            kind = "info";
            break;
        }
        if (progress) {
          msgNode = <ProgressIndicator message={message} progress={progress} progressHandle={args.progressHandle} />;
        }
        if (closeHandle && !message) {
          closeSnackbar(closeHandle);
        } else {
          return enqueueSnackbar(msgNode, { variant: kind as any, persist });
        }
      }
    });
  }, []);

  if (dialogInfo || customDialog)
    return (
      <>
        {customDialog?.map((reg, index) => (
          <React.Fragment key={reg.id ?? index}>{reg.dialog}</React.Fragment>
        ))}
        {dialogInfo && <NotificationDialog {...dialogInfo} />}
      </>
    );
  else if (blockScreenInfo) return <BlockScreen {...blockScreenInfo} />;
  else return null;
}

export function PaperComponent(props: PaperProps) {
  return (
    // <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
    // </Draggable>
  );
}

interface DialogInfo {
  title: string | React.ReactNode;
  message: React.ReactNode;
  buttons: ButtonInfo[];
  titleIcon?: React.ReactElement;
  footerStatus?: string;
  decisionCallback: (decision: number) => void;
  disableDraggable?: boolean;
}

interface NotificationDialogProps extends DialogInfo {}

interface DraggableProps {
  scroll: "paper";
  PaperComponent?: typeof PaperComponent;
}

function NotificationDialog(props: NotificationDialogProps) {
  const draggableProps: DraggableProps | undefined = props.disableDraggable
    ? undefined
    : { scroll: "paper", PaperComponent: PaperComponent };
  const ariaLabel = props.disableDraggable ? "alert-dialog-title" : "draggable-dialog-title";

  return (
    <Dialog
      open={true}
      onClose={() => props.decisionCallback(0)}
      aria-labelledby={ariaLabel}
      {...draggableProps}
      aria-describedby="alert-dialog-description"
    >
      <NotificationDialogContent {...props} />
    </Dialog>
  );
}

function NotificationDialogContent(props: DialogInfo) {
  const { title, message, buttons, titleIcon, footerStatus } = props;
  const { classes } = useDialogStyles();
  const handleClose = (decision = 0) => {
    props.decisionCallback(decision);
  };

  return (
    <>
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {titleIcon ? (
          <div>
            {titleIcon}
            <span className={classes.titleText}>{title}</span>
          </div>
        ) : (
          title
        )}
      </DialogTitle>
      <DialogContent>
        {Array.isArray(message) ? (
          message.map((msg, index) => {
            return (
              <DialogContentText key={index} id="dialog-description">
                {msg}
              </DialogContentText>
            );
          })
        ) : (
          <DialogContentText id="dialog-description">{message}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {footerStatus && <span className={classes.footer}>{footerStatus}</span>}

        {buttons.map(b => {
          return (
            <Button
              key={b.value}
              onClick={() => handleClose(b.value)}
              color={b.default ? "secondary" : "primary"}
              autoFocus={b.default}
            >
              {b.title}
            </Button>
          );
        })}
      </DialogActions>
    </>
  );
}

interface BlockScreenProps extends BlockScreenInfo {
  decisionCallback?: (decision: number) => void;
}

function BlockScreen(props: BlockScreenProps) {
  const { classes } = useBlockScreenStyles();
  const { open, progress, query, title, message, buttons = [], decisionCallback } = props;
  const handleDecision = (decision: number) => {
    decisionCallback && decisionCallback(decision);
  };

  const titleIcon = <ReportProblemOutlinedIcon className={classes.icon} fontSize="large" />;
  const footerText = progress ? `Remaining attempts: ${progress}` : undefined;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      //onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <React.Fragment>
        <Fade in={open && !query}>
          <div className={classes.pending}>
            <WaitIndicatorMH />
          </div>
        </Fade>
        <Slide direction="up" in={!!progress || query} mountOnEnter unmountOnExit>
          <Paper elevation={4} className={classes.paper}>
            <NotificationDialogContent
              footerStatus={footerText}
              decisionCallback={handleDecision}
              titleIcon={titleIcon}
              buttons={buttons}
              message={message || "unknown"}
              title={title || "unknown"}
            />
          </Paper>
        </Slide>
      </React.Fragment>
    </Modal>
  );
}

interface CustomDialogProps {
  decisionCallback?: (decision: number) => void;
}

function CustomDialog(props: CustomDialogProps) {}
