import { IconButton, ResourceKey, useLocalize } from "@emibee/lib-app-common";
import * as React from "react";
import { ReactElement } from "react";
import Button from "@mui/material/Button";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ToolbarDataRangePicker } from "./view/useFetchMoreFilter";

interface DataRangePickerButtonProps {
  label?: ResourceKey;
  range?: [Date, Date] | null;
  changeDateRange: (range?: [Date, Date]) => void;
}

export function DataRangePickerButton(props: DataRangePickerButtonProps) {
  const { range, changeDateRange, label } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const localize = useLocalize();
  let button: ReactElement;
  if (label) {
    button = <Button startIcon={<DateRangeIcon />} onClick={ev => setAnchorEl(ev.currentTarget)}>
      {localize(label)}
    </Button>;
  } else {
    button = <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
      <DateRangeIcon />
    </IconButton>;
  }
  return (
    <>
      {button}
      {anchorEl && (
        <ToolbarDataRangePicker
          range={range}
          anchorEl={anchorEl}
          onChange={changeDateRange}
          onClose={() => setAnchorEl(null)}
        />
      )}
    </>
  );
}