import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "../Theme";
import Link from "@mui/material/Link";
// import logo from '../logo2.svg';
import logo from "../img/mh-hammer-orange-round.svg";
import mhBanner from "../img/motorhammer-logo-fin01.svg";
import {
  buildTextResources,
  IControlApi,
  ILocalizationService,
  Localizer,
  MenuItem,
  useLocalizationService
} from "@emibee/lib-app-common";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import LanguageIcon from "@mui/icons-material/Language";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

import { Locale } from "@mh/common";

const txtRes = buildTextResources({
  scope: "Controls",
  namespace: "Common",
  resources: {
    termsAndPolicy: "By clicking sign up, you agree to our Terms of Service and Privacy Policy."
  }
});

const useStyles = makeStyles()(theme => ({
  avatar: {
    alignSelf: "center",

    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("md")]: {
      width: "36px",
      height: "36px",
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
      width: "72px",
      height: "72px"
    }
  },
  avatarInner: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.down("md")]: {
      paddingLeft: 5,
      width: "32px",
      height: "32px"
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 10,
      width: "64px",
      height: "64px"
    }
  },
  logo: {
    alignSelf: "center",
    borderColor: theme.palette.primary.main,
    width: "12px",
    height: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
      width: "48px",
      height: "48px"
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
      width: "54px",
      height: "54px"
    }
  },
  titleBox: {
    // marginTop: theme.spacing(2),
    width: "100%"
  },
  mhBanner: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '40%',
    //   // height: '40%'
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   // height: '60%'
    // },
    // [theme.breakpoints.up('sm')]: {
    //   // margin: theme.spacing(2),
    //   width: '80%',
    //   // height: '80%'
    // },
    width: "100%",
    marginBottom: theme.spacing(3)
  }
}));

// export function BigLogo(props: {className?: string}) {
//   const { classes } = useStyles();
//   return(<img className={classes.logo} src={logo} alt="logo" height="48" width="48" />)
//   // return (
//     // <Avatar className={classes.avatar}>
//     //   <Avatar className={classes.avatarInner}>
//         {/*<img className={classes.logo} src={logo} className="App-logo" alt="logo" height="48" width="48" />*/}
//       {/*  <img className={classes.logo} src={logo} alt="logo" />*/}
//       {/*</Avatar>*/}
//     {/*</Avatar>*/}
//   // );
// }

// export function MinLogo(props: {className?: string}) {
//   const { classes } = useStyles();
//   return (
//         <img className={classes.logo} src={logo} alt="logo" />
//   );
// }
export function MHBanner(props: { className?: string }) {
  const { classes } = useStyles();
  return (
    <Box className={classes.titleBox} display="flex" alignItems="center" justifyContent="center">
      <img src={mhBanner} className={classes.mhBanner} alt="logo" />
    </Box>
  );
}

export function Logo(props: { className?: string }) {
  const { classes } = useStyles();
  return <img className={classes.logo} src={logo} alt="logo" />;
}

function switchLanguage(language: string, localizationService: ILocalizationService) {
  localizationService.switchLang(Locale[language as keyof typeof Locale]);
}

export function SwitchLanguageIconBox() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const localizationService = useLocalizationService();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const availableLanguages = Object.keys(Locale).map((lang: string) => {
    const l = Locale[lang as keyof typeof Locale];
    return (
      <MenuItem
        key={l}
        onClick={() => {
          localizationService.switchLang(l);
          handleClose();
        }}
      >
        {l.toUpperCase()}
      </MenuItem>
    );
  });
  return (
    <div>
      <Button
        id="switch-language-button"
        aria-controls={open ? "switch-language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <LanguageIcon sx={{ margin: "3px" }} /> {localizationService.activeLang.toUpperCase()}
      </Button>
      <Menu
        id="switch-language-menu"
        aria-labelledby="switch-language-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {availableLanguages}
      </Menu>
    </div>
  );
}

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">Motorhammer</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export function TermsAndPolicy(props: { localize: Localizer; className?: string }) {
  const { localize, className } = props;

  return (
    <Typography className={className} variant="body2" color="textSecondary" align="left">
      {localize(txtRes.termsAndPolicy)}
    </Typography>
  );
}

export function renderError(controlApi: IControlApi<any, any>) {
  if (controlApi.error) {
    return (
      <Alert severity="error" style={{ marginBottom: 8 }}>
        {controlApi.localize(controlApi.error)}
      </Alert>
    );
  } else return undefined;
}
