export enum SecurityRole {
  User = "User",
  Administrator = "Administrator",
  SysAdmin = "SysAdmin"
}

export enum Privileges {
  WriteEnvironmentModel = "Configuration.WriteEnvironmentModel",
  WriteEnvironment = "Configuration.WriteEnvironment",
  WriteConfiguration = "Configuration.WriteConfiguration",
  ReadTask = "TaskService.ReadTask",
  ChangeTask = "TaskService.ChangeTask",
  ReadAccount = "AccountService.ReadAccount",
  LCSReadOperations = "LifeCycleService.ReadOperations",
  LCSWriteOperations = "LifeCycleService.WriteOperations",
  ExperimentalFeature = "ExperimentalFeature",
  ReadSessions = "SessionService.ReadSessions",
  WriteSessions = "SessionService.WriteSessions",
  ReadAuctions = "AuctionService.ReadAuctions",
  ReadCars = "CarService.ReadCars",
  CreateCars = "CarService.CreateCars",
  AuthorTextResources = "TextResources.Author",

  AuctionReminderActions = "AuctionService.SendAuctionReminders",
  DashboardLegacyLinks = "Dashboard.LegacyLinks",
  DashboardAdminLinks = "Dashboard.AdminLinks",
  DashboardControllingLinks = "Dashboard.ControllingLinks",
  ReadDealer = "DealerService.ReadDealer",

  V2Features = "Application.V2Features"
}

type PrivRoleMapType = { [priv: string]: SecurityRole[] };
const PrivilegeRoleMap: PrivRoleMapType = {
  [Privileges.WriteEnvironmentModel]: [SecurityRole.SysAdmin],
  [Privileges.WriteEnvironment]: [SecurityRole.SysAdmin],
  [Privileges.WriteConfiguration]: [SecurityRole.SysAdmin],
  [Privileges.ExperimentalFeature]: [SecurityRole.SysAdmin],
  [Privileges.LCSWriteOperations]: [SecurityRole.SysAdmin],

  [Privileges.ReadSessions]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.WriteSessions]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.ReadTask]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.ReadAccount]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.AuthorTextResources]: [SecurityRole.SysAdmin, SecurityRole.Administrator],

  [Privileges.DashboardLegacyLinks]: [SecurityRole.SysAdmin, SecurityRole.Administrator, SecurityRole.User],
  [Privileges.DashboardAdminLinks]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.DashboardControllingLinks]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.ReadAuctions]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.ReadCars]: [SecurityRole.SysAdmin, SecurityRole.Administrator],

  [Privileges.AuctionReminderActions]: [SecurityRole.SysAdmin, SecurityRole.Administrator],

  [Privileges.ReadDealer]: [SecurityRole.SysAdmin, SecurityRole.Administrator],
  [Privileges.V2Features]: [SecurityRole.SysAdmin, SecurityRole.Administrator]
};

export function authorize(privilege: string, roles?: string[]) {
  if (process.env.REACT_APP_AUTHORIZATION_DISABLED === "true") return true;
  else if (privilege && roles) {
    const privRoles = PrivilegeRoleMap[privilege] as string[];
    return roles.some(r => privRoles?.includes(r));
  } else return false;
}
