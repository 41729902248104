import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.task,
  resources: {
    taskListTitle: "Task List",
    taskDetailsTitle: "Task Details",
    listPageTitle: "Task List",
    sectionTaskData: "Task Details",
    sectionSubTasks: "Sub Tasks",
    sectionTaskActions: "Task Actions",
    sectionTaskContent: "Task Content",
    actionDeleteTaskTitle: "Delete Task",
    actionDeleteTask: "Delete",
    actionDeleteTaskDescription: "Deletes the task and all its sub tasks.",
    actionDeleteTaskQuery: "Do You really want to delete the task and all its sub tasks?",
    actionDeleteTaskSuccessToast: "Task deleted.",
    actionEditTaskTitle: "Edit Task",
    actionEditTask: "Edit",
    actionEditTaskDescription: "Opens the Task-Edit-Dialog.",

    // edit form
    name: "Task",
    state: "State",
    stateReason: "Reason",
    assignedTo: "Assigned To",
    assignedToNotSet: "not yet assigned",
    assignedToPrefix: "assigned to",
    assignedMeButton: "Assign To ME",
    linkAccountPrefix: "by",
    btnTaskDetails: "Details",
    btnTaskUpdate: "Save",
    btnTaskCreate: "Create Task",
    btnTaskPreview: "Preview",
    btnTaskDesign: "Design",

    btnCancel: "Cancel",
    // dialog
    dialogTitle: "Select a task template",
    noActiveTasksFound: "No active tasks."
  }
});
