import { ErrorType, IControlApi, isResourceKey, FeedbackErrorBox, ResourceKey } from "@emibee/lib-app-common";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";
import { BoxPageLayout } from "./layout/BoxPageLayout";

export interface SimplePageStageProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  pendingText?: React.ReactNode;
  errorTitle?: React.ReactNode;
  infoType?: "success" | "info" | "warning" | "error";
  infoText?: React.ReactNode;
  infoTitle?: React.ReactNode;
  leftActionLink?: React.ReactElement;
  rightActionLink?: React.ReactElement;
  renderChildren?: boolean;
  children?: React.ReactNode;
}

export function createStage(stageBuilder: () => SimplePageStageProps) {
  return stageBuilder;
}

type PageInfoStageDetails<StageT extends number> = {
  [stage in StageT]: () => SimplePageStageProps;
};

function safeStageDetails(stage: number, stages: PageInfoStageDetails<any>) {
  const stageBuilder = stages[stage];
  if (!stageBuilder) throw new Error(`Stage ${stage} is not defined in stages property.`);
  else return stageBuilder();
}

interface SimplePageCommonProps {
  title?: React.ReactNode | ResourceKey;
  controlApi: IControlApi<any, any>;
  hideErrorBox?: boolean;
}

interface SimplePageStageControlProps<StageT extends number = number> extends SimplePageCommonProps {
  stage: StageT;
  stages: PageInfoStageDetails<StageT>;
}

export function SimplePageStageControl<StageT extends number = number>(props: SimplePageStageControlProps<StageT>) {
  const { stage, stages, ...rest } = props;

  const [stageDetails, setStageDetails] = React.useState(() => safeStageDetails(stage, stages));
  React.useEffect(() => {
    setStageDetails(safeStageDetails(stage, stages));
  }, [stage, stages]);

  return <SimplePageControl {...stageDetails} {...rest} />;
}

interface SimplePageControlProps extends SimplePageStageProps, SimplePageCommonProps {}

export function SimplePageControl(props: SimplePageControlProps) {
  const { title, controlApi, hideErrorBox } = props;
  const { pending, error, localize } = controlApi;

  // if ((stage !== undefined && !stages) || (stage === undefined && stages))
  //   throw new Error("You need to declare both: stage and stages or none");
  // if (stage && (props.children || props.infoText)) {
  //   console.warn(
  //     "SimplePageControl: You have defined stages. children or infoText will not be used except from stages"
  //   );
  // }
  let {
    children,
    infoType,
    infoText,
    infoTitle,
    leftActionLink,
    rightActionLink,
    pendingText,
    errorTitle,
    renderChildren = true
  } = props;

  if (pending && pendingText) {
    infoTitle = undefined;
    infoText = pendingText;
    infoType = "info";
  }

  if (error && errorTitle && localize) {
    infoTitle = errorTitle;
    infoText = localize(error);
    infoType = "error";
  }

  console.log("SimplePageControl", pending, error);

  return (
    <BoxPageLayout title={isResourceKey(title) ? localize(title) : title}>
      {/*<Container component="div" maxWidth={maxWidth}>*/}
      {/*<Container component="div">*/}
      <Box>
        {!hideErrorBox && !errorTitle && <FeedbackErrorBox controlApi={controlApi} disableRetryButton />}
        {infoText && (
          <Alert severity={infoType}>
            {infoTitle && <AlertTitle>{infoTitle}</AlertTitle>}
            {infoText}
          </Alert>
        )}
        {renderChildren && children}
        {(leftActionLink || rightActionLink) && (
          <Grid container justifyContent="flex-end" style={{ marginTop: 8, fontSize: 12 }}>
            {leftActionLink && (
              <Grid item xs>
                {leftActionLink}
              </Grid>
            )}
            {rightActionLink && <Grid item>{rightActionLink}</Grid>}
          </Grid>
        )}
      </Box>
      {/*</Container>*/}
    </BoxPageLayout>
  );
}
