import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import StoreIcon from "@mui/icons-material/Store";
import { Privileges } from "../../core/authorization";
import { ControlNames, controls, defaults, Domains, domainView } from "../../core/textResourceScopes";
import { DealerDataKit } from "../../data/dealer/Dealer";
import { IDealer, RwfPrivilegeMH } from "@mh/common";
import { textResources } from "./textResources";
import { RwfPermission } from "@emibee/lib-shared";
//
// export enum DealerDetailsTab {
//   details,
//   auctions,
//   actions,
//   files,
//   filterSettings,
//   notificationSettings
// }
export interface DealerDetailsConcurrentProps extends PageConcurrentPrefetchProps<IDealer> {
  // initTab?: DealerDetailsTab;
  dealerId: string;
}

export enum DealerDetailsTabs {
  // auction,
  actionStats,
  auctionList,
  accountList,
  blacklist
}

// export interface DealerListConcurrentProps extends PageConcurrentPrefetchProps<Dealer[]> {}
export type DealerListConcurrentProps = PageConcurrentPrefetchProps<IDealer[]>;

export const DealerDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/dealerDetails",
  displayText: textResources.detailsPageTitle,
  icon: <StoreIcon />,
  auth: true,

  resources: {
    lazy: () => import("./DealerDetailsPage"),
    localeNamespaces: [domainView(Domains.dealer), controls(ControlNames.fileRef), defaults()],
    prefetchGraph: (args?: DealerDetailsConcurrentProps) => ({
      query: DealerDataKit.queries.getDealerDetails,
      args: { dealerId: args?.dealerId },
      watchQuery: true
    })
  },
  params: ["dealerId"],
  // privilege: Privileges.ReadDealer,
  privilege: RwfPrivilegeMH.Dealer,
  permission: RwfPermission.read
});

export const DealerListRoute = buildRouteWithGraphPrefetch({
  path: "/dealerList",
  displayText: textResources.listPageTitle,
  icon: <StoreIcon />,
  auth: true,
  resources: {
    lazy: () => import("./DealerListPage"),
    localeNamespaces: [domainView(Domains.dealer), defaults()],
    prefetchGraph: () => ({
      query: DealerDataKit.queries.getDealerList,
      args: {},
      policy: "cache-first"
    })
  },
  // privilege: Privileges.ReadDealer
  // privilege: Privileges.ExperimentalFeature
  privilege: RwfPrivilegeMH.Dealer,
  permission: RwfPermission.read
});
