import { buildSelectOptions, buildTextResources4Enum, dataKitBuilder } from "@emibee/lib-app-common";
import { CommonSubscribeByOrgIdArgs, DateRangeFetchMoreArgs } from "@emibee/lib-shared";
import {
  AccidentType,
  FuelType,
  GearType,
  GetCarListArgs,
  HailDamageType,
  ICarMin,
  PubSubEventTypeMH,
  ReferencePriceArgs,
  ReferencePriceResult,
  SearchCarsArgs
} from "@mh/common";
import { Domains, enumNamespace } from "../../core/textResourceScopes";
import { CarMinDataKit, getMainImage } from "./CarMin";
import { dateSorter } from "../../tools/utils";

// export enum GearType {
//   manual,
//   semiAutomatic,
//   automatic
// }

export const gearTypeTextResources = buildTextResources4Enum<typeof GearType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "GearType"),
  resources: {
    manual: "Manual",
    semiAutomatic: "Semiautomatic",
    automatic: "Automatic"
  }
});

// export enum FuelType {
//   Diesel,
//   Petrol,
//   Gas,
//   Hybrid,
//   Electric,
//   Hydro,
//   Ethanol,
//   Other
// }

export const fuelTypeTextResources = buildTextResources4Enum<typeof FuelType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "FuelType"),
  resources: {
    Diesel: "Diesel",
    Petrol: "Petrol",
    Gas: "Gas",
    Hybrid: "Hybrid",
    Electric: "Electric",
    Hydro: "Hydro",
    Ethanol: "Ethanol",
    Other: "Other"
  }
});

export interface CarListItem extends ICarMin {
  hp?: number;
  kw?: number;
  transmission?: GearType;
  fuelType?: FuelType;
  accidentalDamage?: AccidentType;
  hailDamageV2?: HailDamageType;
  displacement?: number;

  // temp_gear?: GearType;
}

const carListItemTextResources = CarMinDataKit.extendTextResources<CarListItem>({
  hp: "HP (KW)",
  kw: "Kw",
  transmission: "Gear",
  fuelType: "Fuel",
  accidentalDamage: "Accident",
  hailDamageV2: "Haildamage",
  displacement: "Displacement"
});

export const CarListItemDataKit = dataKitBuilder(carListItemTextResources)
  .fieldType("dateTime", "createdAt", "updatedAt")
  .virtualField("vMainImage")
  .props({
    valueGetter: row => getMainImage(row.mediaFiles)
  })
  .field("fuelType")
  .props({
    options: buildSelectOptions(FuelType, fuelTypeTextResources)
  })
  .field("transmission")
  .props({
    options: buildSelectOptions(GearType, gearTypeTextResources)
  })
  .queries(qb => ({
    getCarList: qb
      .query("getCarList", { allowNull: true, includeCV: true })
      .args<DateRangeFetchMoreArgs<GetCarListArgs>>("GetCarListArgs")
      .incrementalFetch("DateRange")
      .ListResult.allFields(false)
      .subFields("createdBy", "vDisplayName", "id")
      .build(),
    searchCars: qb
      .query("searchCars", { allowNull: true })
      .args<SearchCarsArgs>("SearchCarsArgs", true)
      .ListResult.allFields(false)
      .subFields("createdBy", "vDisplayName", "id")
      .build(),
    carChanged: qb
      .subscription(PubSubEventTypeMH.carChanged, { typeName: "Car" })
      .args<CommonSubscribeByOrgIdArgs>("CommonSubcribeByOrgIdInput", false)
      .Result.allFields(false)
      .subFields("createdBy", "vDisplayName", "id")
      .build(),
    getReferencePrice: qb
      .query("getReferencePrice", { allowNull: true })
      .args<ReferencePriceArgs>("ReferencePriceArgs", true)
      .Result.customType<ReferencePriceResult>()
      .selectFields("averagePrice", "maxPrice", "minPrice", "matchedAuctions", "maker", "model")
      .subFields(
        "matchedAuctions",
        "id",
        "mainImageUri",
        "highestBid",
        "auctionEndTime",
        "startBid",
        "initialRegistration",
        "mileage",
        "transmission",
        "fuelType",
        "condition",
        "maker",
        "model",
        "color"
      )
      // das geht nicht, da mainImage nicht complex ist (müsste man für custom types unterstützen)
      // nachziehen in "todo: fix as soon as nested custom results work"
      // .subBuilder("matchedAuctions", builder =>
      //   builder
      //     .selectFields(
      //       "id",
      //       "mainImage",
      //       "highestBid",
      //       "auctionEndTime",
      //       "startBid",
      //       "initialRegistration",
      //       "mileage",
      //       "transmission",
      //       "fuelType",
      //       "condition",
      //       "maker",
      //       "model",
      //       "color"
      //     )
      //     .subBuilder("mainImage", builder => builder.selectFields("uri", "mediaType", "v2"))
      // )
      .build()
  }))

  .build();

export function sortCarsByCreationTime(cars?: CarListItem[], direction?: "ASC" | "DESC"): CarListItem[] {
  if (cars) {
    return cars.sort(dateSorter("createdAt", direction ?? "DESC"));
  } else return [];
}

// export interface GetCarListArgs {
//   dealerIds?: string[];
// }

// export interface SearchCarsArgs {
//   searchString?: string;
// }
