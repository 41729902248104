import {
  buildTextResources,
  isResourceKey,
  isSelectData,
  Localizer,
  OptionsType,
  SelectData,
  TextField,
  useConstant
} from "@emibee/lib-app-common";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {
  DataGridColumnDef,
  DataGridFilterItem,
  DataGridFilterOperator,
  DataGridFilterOptionsInputProps
} from "./DataGrid";

const dataGridOptionsFilterTextResources = buildTextResources({
  scope: "Controls",
  namespace: "DataGrid.multiselectFilter",
  resources: {
    opIncludes: "includes",
    filterLabelValues: "Values"
  }
});

export function getMultiSelectOperators(
  options: OptionsType,
  localize: Localizer | undefined
): DataGridFilterOperator[] {
  return [
    {
      label: localize ? (localize(dataGridOptionsFilterTextResources.opIncludes) as string) : "includes",
      value: "includes",
      getApplyFilterFn: (filterItem: DataGridFilterItem, column: DataGridColumnDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        const options = filterItem.value ?? []; //.split(",");
        return (rowValue): boolean => {
          const result = rowValue !== undefined && rowValue !== null ? options.includes(rowValue) : false;

          return result;
        };
      },
      InputComponent: MultiSelectInput,
      InputComponentProps: { options, localize }
    }
  ];
}

function MultiSelectInput(props: DataGridFilterOptionsInputProps) {
  const { item, label, applyValue, localize } = props;

  if (!props.options) {
    throw new Error("No options provided!");
  }

  const selectDataOptions = useConstant(() =>
    (isSelectData(props.options!) ? props.options : []).map(opt => ({
      value: opt.value,
      label: isResourceKey(opt.label) ? localize?.(opt.label) ?? opt.label.fallback : opt.label ?? opt.value
    }))
  );
  const convertValue = (values: (string | number)[]) =>
    values.map(v => selectDataOptions.find(sd => sd.value === v)).filter(v => v!!) as SelectData[];

  const [anchorEl, setAnchor] = React.useState<HTMLElement>();
  const [filterValueState, setFilterValueState] = React.useState<SelectData[]>(() => convertValue(item.value ?? []));

  const isOptionSelected = (option: SelectData) => filterValueState.some(v => v.value === option.value);
  const onToggleOption = (option: SelectData) => {
    if (isOptionSelected(option)) {
      setFilterValueState([...filterValueState.filter(v => v.value !== option.value)]);
    } else {
      setFilterValueState([...filterValueState, option]);
    }
  };

  const onClose = () => {
    setAnchor(undefined);
    applyValue({ ...item, value: filterValueState.map(v => v.value) });
  };

  const stringVal = filterValueState.map(val => val.label ?? val.value).join(", ");

  const buildMenuItems = () => {
    return selectDataOptions.map(opt => {
      const selected = isOptionSelected(opt);

      return (
        <MenuItem dense key={opt.value} onClick={() => onToggleOption(opt)} selected={selected}>
          {selected && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
          <ListItemText inset={!selected}> {opt.label}</ListItemText>
        </MenuItem>
      );
    });
  };
  // console.log("Cloick", anchorEl.current?.ownerDocument.documentElement);
  return (
    <>
      <TextField
        label={label ?? "Values"}
        value={stringVal}
        name={`${item.field ?? "?"}-options`}
        variant="outlined"
        size="small"
        noForm
        style={{ caretColor: "transparent" }}
        inputProps={{ style: { cursor: "pointer" } }}
        focused={anchorEl ? true : undefined}
        onClick={ev => setAnchor(ev.currentTarget)}
      />
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
        {buildMenuItems()}
      </Menu>
    </>
  );
}
