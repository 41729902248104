import {
  buildSelectOptions,
  buildTextResources4Data,
  buildTextResources4Enum,
  dataKitBuilder
} from "@emibee/lib-app-common";
import { CarListItemDataKit, fuelTypeTextResources, gearTypeTextResources } from "./CarListItem";

import { DateRangeFetchMoreArgs } from "@emibee/lib-shared";
import {
  AccidentType,
  AirconditionType,
  CheckVinArgs,
  CheckbookType,
  CreateCarArgs,
  DeleteCarArgs,
  FuelType,
  GearType,
  GetCarDetailsArgs,
  GetCarListArgs,
  HailDamageType,
  ICarV2,
  ProcessVinArgs,
  PublishCarArgs,
  QuickCheckFields
} from "@mh/common";

import { Domains, enumNamespace } from "../../core/textResourceScopes";

// export interface ICarV2 extends CarListItem {
//   owner?: string;
//   description?: string;
//   condition?: string;
//   equipment?: string;
//   datEquipment?: [string];
//   seriesEquipment?: [string];
//   inspection?: string;
//   aircondition?: AirconditionType;
//   doors?: number;
//   keys?: number;
//   serviceHistory?: CheckbookType;
//   tax?: string;
//   canBeExported?: string;
//   previousOwners?: number;
//   displacement?: number;
//   vmax?: number;
//   acceleration?: number;
//   cost?: number;
//   cylinder?: string;
//   cylinderArrangement?: string;
//   rotationsOnMaxPower?: number;
//   rotationsOnMaxTorque?: number;
//   torque?: number;
//   accident?: AccidentType;
//   hailDamage?: HailDamageType;
//   emissionClass?: string;
//   color?: string;
//   auctionCount?: number;
//   isDeleted?: boolean;
//   isCloned?: boolean;
//   quickCheckFields: QuickCheckFields;
// }

export const CarDetailsTextResources = CarListItemDataKit.extendTextResources<ICarV2>({
  // createdBy: "Owner",
  // description: "Description",
  condition: "Condition",
  equipment: "Equipment",
  quickCheckLegalHint: "Legal Hint",
  // datEquipment: "DAT equipment",
  // seriesEquipment: "Series equipment",
  inspection: "Inspection",
  airconditionType: "Aircondition",
  doors: "Doors",
  keys: "Keys",

  serviceHistory: "Checkbook",
  vatDeductible: "Tax",
  exportable: "Can be exported",
  previousOwners: "Previous owners",
  // displacement: "Displacement",
  purchasePrice: "Cost",
  // accidentalDamage: "Accident",
  // hailDamageV2: "Hail damage",

  color: "Color",
  // createdBy: "Created By",
  updatedBy: "Updated By",
  // fuelType: "Fuel",
  // transmission: "Transmission",

  // ownerOrgId: "Owner Org",
  // mediaFiles: "Media Files",
  // mainImageUri: "Main Image",
  isLocked: "is locked",
  version: "Version",
  isInAuction: "in Auction",
  carState: "Car State",
  versionedRefs: "Versioned Refs",
  isCloneable: "Is Cloneable",
  assessmentLink: "Professional Car Check",
  // quickCheckFields: "Quick Car Check",
  datEquipment: "DAT Equipment",
  assessment: "Assessment",
  highlights: "Overview"
});

export const hailDamageTypeTextResources = buildTextResources4Enum<typeof HailDamageType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "HailDamageType"),
  resources: {
    no: "No hail damage",
    yes: "Hail damage present",
    unknown: "unknown"
  }
});

export const airconditionTypeTextResources = buildTextResources4Enum<typeof AirconditionType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "AirconditionType"),
  resources: {
    manual: "Klimaanlage",
    auto: "Automatic air conditioning",
    no: "No air conditioning"
  }
});

export const accidentTypeTextResources = buildTextResources4Enum<typeof AccidentType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "AccidentType"),
  resources: {
    no: "accident free",
    repaired: "Repaired accident damage",
    notRepaired: "Accident not repaired",
    unknown: "unknown"
  }
});

// export enum CheckbookType {
//   yes,
//   no,
//   unknown
// }

export const checkbookTypeTextResources = buildTextResources4Enum<typeof CheckbookType>({
  scope: "Data",
  namespace: enumNamespace(Domains.car, "CheckbookType"),
  resources: {
    yes: "Yes",
    no: "No",
    unknown: "Unknown"
  }
});

// export interface QuickCheckFields {
//   engine?: boolean;
//   transmission?: boolean;
//   windowElectronics?: boolean;
//   aircondition?: boolean;
//   boardElectronics?: boolean;
//   testDrive30kmh?: boolean;
//   testDrive50kmh?: boolean;
//   testDrive120kmh?: boolean;
// }

export const quickCheckFieldsTextResources = buildTextResources4Data<QuickCheckFields>({
  scope: "Data",
  namespace: "Car.QuickCheck",
  resources: {
    engine: "Engine Check",
    transmission: "Transmission Check",
    windowElectronics: "Window Electronics Check",
    aircondition: "Aircondition Check",
    boardElectronics: "Board Electronics Check",
    testDrive30kmh: "Testdrive up to 30 kmh",
    testDrive50kmh: "Testdrive up to 50 kmh",
    testDrive120kmh: "Testdrive up to 120 kmh"
  }
});

export const QuickCheckFieldsDataKit = dataKitBuilder(quickCheckFieldsTextResources).build();

export const CarDetailsDataKit = CarListItemDataKit.extend(CarDetailsTextResources)
  .fieldType("boolean", "vatDeductible", "exportable", "quickCheckLegalHint")
  .fieldType("number", "purchasePrice", "mileage", "keys", "doors", "previousOwners", "displacement", "hp")
  // .virtualField("vMainImage")
  // .props({
  //   valueGetter: row => getMainImage(row.images)
  // })
  .field("vin")
  .props({})
  .field("fuelType")
  .props({
    options: buildSelectOptions(FuelType, fuelTypeTextResources)
  })

  .field("transmission")
  .props({
    options: buildSelectOptions(GearType, gearTypeTextResources)
  })
  .field("hailDamageV2")
  .props({
    options: buildSelectOptions(HailDamageType, hailDamageTypeTextResources)
  })
  .field("airconditionType")
  .props({
    options: buildSelectOptions(AirconditionType, airconditionTypeTextResources)
  })
  .field("accidentalDamage")
  .props({
    options: buildSelectOptions(AccidentType, accidentTypeTextResources)
  })
  .field("serviceHistory")
  .props({
    options: buildSelectOptions(CheckbookType, checkbookTypeTextResources)
  })

  // .field("vatDeductible")
  // .props({ type: "boolean" })
  // .field("exportable")
  // .props({ type: "boolean" })
  // .field("mediaFiles")
  // .props({ complex: MediaFileDataKit })
  // .field("mediaFiles")
  // .props({
  //   valueFormatter: row => {
  //     console.log("row", row);
  //     return row[0];
  //   }
  // })
  // .field("_dealer")
  // .props({ complex: DealerDataKit })
  // .field("images")
  // .props({ complex: ImageDataKit })
  .queries(qb => ({
    getCarDetails: qb
      .query("getCarDetails", { allowNull: true })
      .args<GetCarDetailsArgs>("GetCarDetailsArgs", true)
      .Result.allFields(true)
      .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
      .subFields("createdBy", "id", "vDisplayName")
      // todo: fix this
      // //@ts-ignore
      // .subFields("_dealer", "name", "id", "orgId")
      // //@ts-ignore
      // .subFields("images", "isMain", "thumbUri", "uri")
      //  .subBuilder("mediaFiles", builder => builder.customType<CarMediaFile>().selectFields("image"))
      //@ts-ignore
      // .subBuilder("mediaFiles", builder =>
      //   builder
      //     .customType<CarMediaFile>()
      //     .selectFields("mediaType")
      //     .subBuilder("media", builder => builder.customType<CarImage>().selectFields("isMain", "thumbUri", "uri"))
      // )
      // .subBuilder("quickCheckFields", builder => {
      //   builder.allFields(false);
      // })
      .build(),
    createCar: qb
      .mutation("createCar")
      .args<CreateCarArgs>("CreateCarArgs", true)
      .Result.allFields(true)
      .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
      .subFields("createdBy", "id", "vDisplayName")
      // .subFields("_dealer", "name", "id")
      .build(),
    updateCar: qb
      .mutation("updateCar")
      .args<CreateCarArgs>("UpdateCarArgs", true)
      .Result.allFields(true)
      .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
      .subFields("createdBy", "id", "vDisplayName")
      // .subFields("_dealer", "name", "id")
      .build(),
    publishCar: qb
      .mutation("publishCar")
      .args<PublishCarArgs>("publishCarArgs", true)
      .Result.allFields(true)
      // .subFields("_dealer", "name", "id")
      .build(),
    deleteCar: qb
      .mutation("deleteCar")
      .args<DeleteCarArgs>("DeleteCarArgs", true)
      .PrimitiveResult<boolean>()
      .build(),
    checkVin: qb
      .query("checkVin", { allowNull: true })
      .args<CheckVinArgs>("CheckVinArgs", true)
      .Result.customType<CheckVinResult>()
      .selectFields("car", "multipleHits", "vehicleArray", "nameSpace", "vinNumber", "techInfo")
      .subFields(
        "car",
        "maker",
        "model",
        "hp",
        "color",
        "variant",
        "transmission",
        "fuelType",
        "vin",
        "datEquipment",
        "displacement",
        "doors"
      )
      .build(),
    processVin: qb
      .query("processVin", { allowNull: true })
      .args<ProcessVinArgs>("ProcessVinArgs", true)
      .Result.customType<CheckVinResult>()
      .selectFields("car", "multipleHits", "vehicleArray", "nameSpace", "vinNumber", "techInfo")
      .subFields(
        "car",
        "maker",
        "model",
        "hp",
        "color",
        "variant",
        "transmission",
        "fuelType",
        "vin",
        "datEquipment",
        "displacement",
        "doors"
      )
      .build(),
    getCarListExt: qb
      .query("getCarList", { allowNull: true })
      .args<DateRangeFetchMoreArgs<GetCarListArgs>>("GetCarListArgs")
      .incrementalFetch("DateRange")
      .ListResult.allFields(false)
      .subFields("createdBy", "name", "id", "vDisplayName")
      .subFields("assessment", "statusChecks", "tireSets", "paintCoating")
      .build()
  }))
  .build();

export interface CheckVinResult {
  vinNumber: string;
  car: ICarV2;
  techInfo: string;
  rawData: string;
  multipleHits: boolean;
  nameSpace: string;
  vehicleArray: [any];
}
