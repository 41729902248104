import { buildRouteWithParams, PageConcurrentResources } from "@emibee/lib-app-common";
import { ControlNames, controls, data, Domains, enumNamespace, pages } from "../../core/textResourceScopes";
import { AccountPages } from "./CommonTextResources";
import {
  ChangeVerificationMailPageProps,
  EmailVerificationPageProps,
  ForgotPasswordPageProps,
  RecoverPasswordPageProps,
  SecondFactorVerificationPageProps,
  SignUpPageProps
} from "./fieldPresets";

// export interface UserDetailsConcurrentProps extends PageConcurrentPrefetchProps<UserDetails> {
//   initTab?: AccountDetailsTab;
//   accountId: string;
//   legacy?: boolean;
// }

// export const TextResourceNamespace = "TaskList";
// export const textResources = buildTextResourcesMH({
//   scope: "Route",
//   namespace: TextResourceNamespace,
//   resources: {
//     routeListText: "Task List",
//     routeDetailsText: "Task Details"
//   }
// });

// export const AccountDetailsRoute = buildRouteWithGraphPrefetch({
//   path: "/accDetails",
//   displayText: textResources.routeDetailsText,
//   icon: <PersonIcon />,
//   auth: true,
//   privilege: Privileges.ReadAccount,
//   resources: {
//     lazy: () => import("./AccountDetailsPage"),
//     localeNamespaces: [pages(UserDetailsViewRes), controls(FileRef)],
//     prefetchGraph: (args?: UserDetailsConcurrentProps) => ({
//       query: UserDetailsDataKit.queries.getAccount,
//       args: { id: args?.accountId }
//     })
//   },
//   params: ["accountId", "initTab", "legacy"]
// });

export const SignUpRoute = buildRouteWithParams<SignUpPageProps>({
  path: "/signup",
  resources: {
    lazy: () => import("./SignUpPage"),
    localeNamespaces: [
      data(Domains.account),
      data(Domains.organization),
      data(Domains.common),
      pages(AccountPages),
      controls(ControlNames.fileRef)
    ]
  },
  params: ["email", "newEmail", "stage", "token", "affiliateCode", "code"]
});

export const ForgotPasswordRoute = buildRouteWithParams<ForgotPasswordPageProps>({
  path: "/resetPwd",
  resources: {
    lazy: () => import("./ForgotPasswordPage"),
    localeNamespaces: pages(AccountPages)
  },
  params: ["email", "redirect"]
});

export const VerifyEmailRoute = buildRouteWithParams<EmailVerificationPageProps>({
  path: "/verify",
  resources: {
    lazy: () => import("./EmailVerificationPage"),
    localeNamespaces: [enumNamespace(Domains.passwordInput, "PasswordScore"), pages(AccountPages)]
  },
  params: ["token", "redirect"]
});

export const ChangeVerificationMailRoute = buildRouteWithParams<ChangeVerificationMailPageProps>({
  path: "/changeEmailV",
  resources: {
    lazy: () => import("./ChangeVerificationMailPage"),
    localeNamespaces: pages(AccountPages)
  },
  params: ["email"]
  //fetch: () =>
});

export const RecoverPasswordRoute = buildRouteWithParams<RecoverPasswordPageProps>({
  path: "/recoverPwd",
  resources: {
    lazy: () => import("./RecoverPasswordPage"),
    localeNamespaces: [enumNamespace(Domains.passwordInput, "PasswordScore"), pages(AccountPages)]
  },
  params: ["token", "redirect"]
});

export const SecondFactorVerificationRoute = buildRouteWithParams<SecondFactorVerificationPageProps>({
  path: "/verify2ndF",
  resources: {
    lazy: () => import("./SecondFactorVerificationPage"),
    localeNamespaces: pages(AccountPages)
  },
  params: ["token"]
});

export const SecondFactorInfoPageConcurrent: PageConcurrentResources = {
  lazy: () => import("./SecondFactorInfoPage"),
  localeNamespaces: pages(AccountPages)
};
