import { buildSelectOptions, buildTextResources4Data, dataKitBuilder } from "@emibee/lib-app-common";
import { Domains, dataNamespace } from "../../core/textResourceScopes";
import { CountryCode, PostalAddress } from "@mh/common";
import { CountryNameTextResources } from "../common";

const postalAddressTextResources = buildTextResources4Data<PostalAddress>({
  scope: "Data",
  namespace: dataNamespace(Domains.organization, "PostalAddress"),
  resources: {
    name: "Recepient Name",
    nameAddition: "Name Addition",
    street: "Street",
    zip: "Postal Code",
    city: "City",
    country: "Country",
    countryCode: "Country Code",
    zipAndCity: "Location",
    state: "State",
    stateCode: "State Code",
    location: "Coordinates"
    // formatted_address: "Found Address"
  }
});

export const PostalAddressDataKit = dataKitBuilder(postalAddressTextResources)
  .virtualField("zipAndCity")
  .props({
    valueGetter: row => `${row.zip ?? ""} ${row.city || "-"}`
  })
  .field("country")
  .props({
    options: buildSelectOptions(CountryCode, CountryNameTextResources)
  })
  .build();
