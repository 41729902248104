"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.manufacturerArray = exports.V2ToLegacyStatusCheckMapping = exports.ExecutionState = exports.StatusChecks = exports.getCarEnumValue = exports.getGearTypeEnumValue = exports.CarImageSize = exports.MediaTypes = exports.CheckbookEnumToValue = exports.CheckbookType = exports.AccidentEnumToValue = exports.AccidentType = exports.AirconditionEnumToValue = exports.AirconditionType = exports.HailDamageEnumToValue = exports.HailDamageType = exports.GearTypeEnumToValue = exports.GearType = exports.FuelTypeEnumToValue = exports.FuelType = exports.CarState = exports.HP_KW_FACTOR = void 0;
exports.getV2StatusCheckKeyFromLegacyType = getV2StatusCheckKeyFromLegacyType;
exports.HP_KW_FACTOR = 1.359;
var CarState;
(function (CarState) {
    CarState[CarState["ACTIVE"] = 0] = "ACTIVE";
    CarState[CarState["INACTIVE"] = 1] = "INACTIVE";
    CarState[CarState["DELETED"] = 2] = "DELETED";
    CarState[CarState["SOLD"] = 3] = "SOLD";
})(CarState || (exports.CarState = CarState = {}));
var FuelType;
(function (FuelType) {
    FuelType[FuelType["Diesel"] = 0] = "Diesel";
    FuelType[FuelType["Petrol"] = 1] = "Petrol";
    FuelType[FuelType["Gas"] = 2] = "Gas";
    FuelType[FuelType["Hybrid"] = 3] = "Hybrid";
    FuelType[FuelType["Electric"] = 4] = "Electric";
    FuelType[FuelType["Hydro"] = 5] = "Hydro";
    FuelType[FuelType["Ethanol"] = 6] = "Ethanol";
    FuelType[FuelType["Other"] = 7] = "Other";
})(FuelType || (exports.FuelType = FuelType = {}));
var FuelTypeEnumToValue = function (key) {
    switch (key) {
        case FuelType.Diesel:
            return "FUEL_DIESEL";
            break;
        case FuelType.Petrol:
            return "FUEL_PETROL";
            break;
        case FuelType.Gas:
            return "FUEL_GAS";
            break;
        case FuelType.Hybrid:
            return "FUEL_HYBRID";
            break;
        case FuelType.Electric:
            return "FUEL_ELECTRIC";
            break;
        case FuelType.Hydro:
            return "FUEL_HYDRO";
            break;
        case FuelType.Ethanol:
            return "FUEL_ETHANOL";
            break;
        case FuelType.Other:
            return "FUEL_OTHER";
            break;
        default:
            return "";
            break;
    }
};
exports.FuelTypeEnumToValue = FuelTypeEnumToValue;
var GearType;
(function (GearType) {
    GearType[GearType["manual"] = 0] = "manual";
    GearType[GearType["semiAutomatic"] = 1] = "semiAutomatic";
    GearType[GearType["automatic"] = 2] = "automatic";
})(GearType || (exports.GearType = GearType = {}));
var GearTypeEnumToValue = function (key) {
    switch (key) {
        case GearType.manual:
            return "GEAR_STICK";
            break;
        case GearType.semiAutomatic:
            return "GEAR_HALF_AUTOMATIC";
            break;
        case GearType.automatic:
            return "GEAR_AUTOMATIC";
            break;
        default:
            return "";
            break;
    }
};
exports.GearTypeEnumToValue = GearTypeEnumToValue;
var HailDamageType;
(function (HailDamageType) {
    HailDamageType[HailDamageType["no"] = 0] = "no";
    HailDamageType[HailDamageType["yes"] = 1] = "yes";
    HailDamageType[HailDamageType["unknown"] = 2] = "unknown";
})(HailDamageType || (exports.HailDamageType = HailDamageType = {}));
var HailDamageEnumToValue = function (key) {
    switch (key) {
        case HailDamageType.no:
            return "HAIL_DAMAGE_NO";
        case HailDamageType.yes:
            return "HAIL_DAMAGE_YES";
        case HailDamageType.unknown:
            return "HAIL_DAMAGE_UNKNOWN";
        default:
            return undefined;
    }
};
exports.HailDamageEnumToValue = HailDamageEnumToValue;
var AirconditionType;
(function (AirconditionType) {
    AirconditionType[AirconditionType["manual"] = 0] = "manual";
    AirconditionType[AirconditionType["auto"] = 1] = "auto";
    AirconditionType[AirconditionType["no"] = 2] = "no";
})(AirconditionType || (exports.AirconditionType = AirconditionType = {}));
var AirconditionEnumToValue = function (key) {
    switch (key) {
        case AirconditionType.manual:
            return "AIRCONDITION_MANUAL";
        case AirconditionType.auto:
            return "AIRCONDITION_AUTO";
        case AirconditionType.no:
            return "AIRCONDITION_NO";
        default:
            return "";
    }
};
exports.AirconditionEnumToValue = AirconditionEnumToValue;
var AccidentType;
(function (AccidentType) {
    // yes,
    AccidentType[AccidentType["no"] = 0] = "no";
    AccidentType[AccidentType["repaired"] = 1] = "repaired";
    AccidentType[AccidentType["notRepaired"] = 2] = "notRepaired";
    AccidentType[AccidentType["unknown"] = 3] = "unknown";
})(AccidentType || (exports.AccidentType = AccidentType = {}));
var AccidentEnumToValue = function (key) {
    switch (key) {
        // case AccidentType.yes:
        //   return "ACCIDENT_YES";
        case AccidentType.no:
            return "ACCIDENT_NO";
        case AccidentType.repaired:
            return "ACCIDENT_REPAIRED";
        case AccidentType.notRepaired:
            return "ACCIDENT_NOT_REPAIRED";
        case AccidentType.unknown:
            return "ACCIDENT_UNKNOWN";
        default:
            return "";
    }
};
exports.AccidentEnumToValue = AccidentEnumToValue;
var CheckbookType;
(function (CheckbookType) {
    CheckbookType[CheckbookType["yes"] = 0] = "yes";
    CheckbookType[CheckbookType["no"] = 1] = "no";
    CheckbookType[CheckbookType["unknown"] = 2] = "unknown";
})(CheckbookType || (exports.CheckbookType = CheckbookType = {}));
var CheckbookEnumToValue = function (key) {
    switch (key) {
        case CheckbookType.yes:
            return "YES";
        case CheckbookType.no:
            return "NO";
        case CheckbookType.unknown:
            return "UNKNOWN";
        default:
            return "";
    }
};
exports.CheckbookEnumToValue = CheckbookEnumToValue;
var MediaTypes;
(function (MediaTypes) {
    MediaTypes[MediaTypes["image"] = 0] = "image";
    MediaTypes[MediaTypes["video"] = 1] = "video";
    MediaTypes[MediaTypes["audio"] = 2] = "audio";
    MediaTypes[MediaTypes["document"] = 3] = "document";
})(MediaTypes || (exports.MediaTypes = MediaTypes = {}));
var CarImageSize;
(function (CarImageSize) {
    CarImageSize[CarImageSize["orig"] = 0] = "orig";
    CarImageSize[CarImageSize["large"] = 1] = "large";
    CarImageSize[CarImageSize["medium"] = 2] = "medium";
    CarImageSize[CarImageSize["small"] = 3] = "small";
    CarImageSize[CarImageSize["thumb"] = 4] = "thumb";
})(CarImageSize || (exports.CarImageSize = CarImageSize = {}));
var getGearTypeEnumValue = function (legacyType) {
    switch (legacyType) {
        case "GEAR_STICK":
            return GearType.manual;
            break;
        case "GEAR_HALF_AUTOMATIC":
            return GearType.semiAutomatic;
            break;
        case "GEAR_AUTOMATIC":
            return GearType.automatic;
            break;
        default:
            return null;
            break;
    }
};
exports.getGearTypeEnumValue = getGearTypeEnumValue;
var getCarEnumValue = function (enumObj, val) {
    if (val === "ACCIDENT_NOT_REPAIRED") {
        return enumObj.notRepaired;
    }
    try {
        var returnVal = Object.values(enumObj)
            .filter(function (value) { return typeof value === "string"; })
            .findIndex(function (key) { return val.endsWith(key.toUpperCase()); });
        return returnVal > -1 ? returnVal : undefined;
        // console.log(
        //   "🚀 ~ returnVal: ",
        //   returnVal
        // );
        // return returnVal;
    }
    catch (e) {
        return undefined;
    }
};
exports.getCarEnumValue = getCarEnumValue;
var StatusChecks;
(function (StatusChecks) {
    StatusChecks[StatusChecks["engine"] = 0] = "engine";
    StatusChecks[StatusChecks["transmission"] = 1] = "transmission";
    StatusChecks[StatusChecks["clutch"] = 2] = "clutch";
    StatusChecks[StatusChecks["brakes"] = 3] = "brakes";
    StatusChecks[StatusChecks["steering"] = 4] = "steering";
    StatusChecks[StatusChecks["chassis"] = 5] = "chassis";
    StatusChecks[StatusChecks["exhaust"] = 6] = "exhaust";
    StatusChecks[StatusChecks["engineOilLevel"] = 7] = "engineOilLevel";
    StatusChecks[StatusChecks["coolingWaterLevel"] = 8] = "coolingWaterLevel";
    StatusChecks[StatusChecks["battery"] = 9] = "battery";
    StatusChecks[StatusChecks["instrumentCluster"] = 10] = "instrumentCluster";
    StatusChecks[StatusChecks["airCondition"] = 11] = "airCondition";
    StatusChecks[StatusChecks["centralLockingSystem"] = 12] = "centralLockingSystem";
    StatusChecks[StatusChecks["electricWindows"] = 13] = "electricWindows";
})(StatusChecks || (exports.StatusChecks = StatusChecks = {}));
var ExecutionState;
(function (ExecutionState) {
    ExecutionState[ExecutionState["NotExecuted"] = 0] = "NotExecuted";
    ExecutionState[ExecutionState["Ok"] = 1] = "Ok";
    ExecutionState[ExecutionState["NotOk"] = 2] = "NotOk";
})(ExecutionState || (exports.ExecutionState = ExecutionState = {}));
function getV2StatusCheckKeyFromLegacyType(type) {
    // helper wird gebraucht für unterschiedliche schreibweise in legacy vs V2
    switch (type) {
        case "aircondition":
            return StatusChecks.airCondition;
        case "exhaustSystem":
            return StatusChecks.exhaust;
        default:
            return StatusChecks[type];
    }
}
exports.V2ToLegacyStatusCheckMapping = (_a = {},
    _a[StatusChecks.engine] = {
        type: "engine",
        key: "ENGINE",
        checkExecuted: false,
    },
    _a[StatusChecks.transmission] = {
        type: "transmission",
        key: "TRANSMISSION",
        checkExecuted: false,
    },
    _a[StatusChecks.clutch] = {
        type: "clutch",
        key: "CLUTCH",
        checkExecuted: false,
    },
    _a[StatusChecks.brakes] = {
        type: "brakes",
        key: "BRAKES",
        checkExecuted: false,
    },
    _a[StatusChecks.steering] = {
        type: "steering",
        key: "STEERING",
        checkExecuted: false,
    },
    _a[StatusChecks.chassis] = {
        type: "chassis",
        key: "CHASSIS",
        checkExecuted: false,
    },
    _a[StatusChecks.exhaust] = {
        type: "exhaustSystem",
        key: "EXHAUST_SYSTEM",
        checkExecuted: false,
    },
    _a[StatusChecks.engineOilLevel] = {
        type: "engineOilLevel",
        key: "ENGINE_OIL_LEVEL",
        checkExecuted: false,
    },
    _a[StatusChecks.coolingWaterLevel] = {
        type: "coolingWaterLevel",
        key: "COOLING_WATER_LEVEL",
        checkExecuted: false,
    },
    _a[StatusChecks.battery] = {
        type: "battery",
        key: "BATTERY",
        checkExecuted: false,
    },
    _a[StatusChecks.instrumentCluster] = {
        type: "instrumentCluster",
        key: "INSTRUMENT_CLUSTER",
        checkExecuted: false,
    },
    _a[StatusChecks.airCondition] = {
        type: "aircondition",
        key: "AIRCONDITION",
        checkExecuted: false,
    },
    _a[StatusChecks.centralLockingSystem] = {
        type: "centralLockingSystem",
        key: "CENTRAL_LOCKING_SYSTEM",
        checkExecuted: false,
    },
    _a[StatusChecks.electricWindows] = {
        type: "electricWindows",
        key: "ELECTRIC_WINDOWS",
        checkExecuted: false,
    },
    _a);
exports.manufacturerArray = [
    "Abarth",
    "AC",
    "Acura",
    "Aiways",
    "Aixam",
    "Alfa Romeo",
    "ALPINA",
    "Artega",
    "Asia Motors",
    "Aston Martin",
    "Audi",
    "Austin",
    "Austin Healey",
    "BAIC",
    "Bentley",
    "BMW",
    "Borgward",
    "Brilliance",
    "Bugatti",
    "Buick",
    "BYD",
    "Cadillac",
    "Casalini",
    "Caterham",
    "Chatenet",
    "Chevrolet",
    "Chrysler",
    "Citroën",
    "Cobra",
    "Corvette",
    "Cupra",
    "Dacia",
    "Daewoo",
    "Daihatsu",
    "DeTomaso",
    "DFSK",
    "Dodge",
    "Donkervoort",
    "DS Automobiles",
    "e.GO",
    "Elaris",
    "Ferrari",
    "Fiat",
    "Fisker",
    "Ford",
    "GAC Gonow",
    "Gemballa",
    "Genesis",
    "GMC",
    "Grecav",
    "Hamann",
    "Holden",
    "Honda",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Isuzu",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Kia",
    "Koenigsegg",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Landwind",
    "LEVC",
    "Lexus",
    "Ligier",
    "Lincoln",
    "Lotus",
    "Lynk&Co",
    "Mahindra",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "Microcar",
    "MINI",
    "Mitsubishi",
    "Morgan",
    "Nio",
    "Nissan",
    "NSU",
    "Oldsmobile",
    "Opel",
    "ORA",
    "Pagani",
    "Peugeot",
    "Piaggio",
    "Plymouth",
    "Polestar",
    "Pontiac",
    "Porsche",
    "Proton",
    "Puch",
    "Renault",
    "Renault Trucks",
    "Rimac",
    "Rolls-Royce",
    "Rover",
    "Ruf",
    "Saab",
    "SAIC",
    "Saleen",
    "Samsung",
    "Saturn",
    "Scania",
    "Seat",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "TagAZ",
    "Tazzari",
    "Tesla",
    "Toyota",
    "Trabant",
    "Triumph",
    "Tvr",
    "Uaz",
    "Vauxhall",
    "Venturi",
    "Vespa",
    "Victory",
    "Volkswagen",
    "Volvo",
    "Wallyscar",
    "Wartburg",
    "Westfield",
    "Wiesmann",
    "Xpeng",
    "Yamaha",
    "Yugo",
    "Zastava",
    "Zotye",
    "Andere",
];
