import { ClientServiceProvider, ensureClientLogger, LoginRoutesSubscriber } from "@emibee/lib-app-common";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import { AppConcurrent } from "./common/routes";
import { GoogleAnalytics } from "./controls/GoogleTagManager";
import { NotificationProvider } from "./controls/NotificationProvider";
import { Loading } from "./controls/WaitIndicatorMH";
import { setupClient } from "./core/setupClient";
import {
  ChangeVerificationMailRoute,
  ForgotPasswordRoute,
  RecoverPasswordRoute,
  SecondFactorInfoPageConcurrent,
  SecondFactorVerificationRoute,
  SignUpRoute,
  VerifyEmailRoute
} from "./pages/account/routes";
import { SignInPageConcurrent, SignInRoute } from "./pages/account/SignInPage";
import { SignOutRoute } from "./pages/account/SignOutPage";
import * as serviceWorker from "./serviceWorker";
import { Theme } from "./Theme";

// register global logger variable
ensureClientLogger();

// init client
const mhClient = setupClient();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Theme>
    <SnackbarProvider maxSnack={3}>
      <CssBaseline />
      <input type={"hidden"} id={"version"} value={process.env.REACT_APP_COMMIT_SHORT} />
      <ClientServiceProvider service={mhClient}>
        <NotificationProvider />
        <GoogleAnalytics />
        <LoginRoutesSubscriber
          loginPage={SignInPageConcurrent}
          secondFactorPage={SecondFactorInfoPageConcurrent}
          appComponent={AppConcurrent}
          routesSubscription={[
            SignUpRoute,
            SignInRoute,
            SignOutRoute,
            VerifyEmailRoute,
            SecondFactorVerificationRoute,
            ChangeVerificationMailRoute,
            ForgotPasswordRoute,
            RecoverPasswordRoute
          ]}
          loadingComponent={<Loading />}
        />
      </ClientServiceProvider>
    </SnackbarProvider>
  </Theme>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
