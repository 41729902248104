import { buildTextResources4Enum } from "@emibee/lib-app-common";
import { CountryCode, Locale } from "@mh/common";

import { Domains, enumNamespace } from "../core/textResourceScopes";

// export enum CountryName {
//   Austria = "Österreich",
//   Germany = "Deutschland"
// }

export const CountryNameTextResources = buildTextResources4Enum<typeof CountryCode>({
  scope: "Data",
  namespace: enumNamespace(Domains.common, "CountryName"),
  resources: {
    // Austria: "Austria",
    // Germany: "Germany"
    AT: "Austria",
    DE: "Germany",
    PL: "Poland",
    CZ: "Czechia",
    HU: "Hungary",
    HR: "Croatia",
    SI: "Slovenia",
    SK: "Slovakia"
  }
});

export const LanguageEnumTextResources = buildTextResources4Enum<typeof Locale>({
  scope: "Data",
  namespace: enumNamespace(Domains.common, "Language"),
  resources: {
    English: "English",
    German: "German",
    Polish: "Polish",
    Czech: "Czech"
  }
});

export enum PlaceholderImages {
  // Place all public images in the bucket: s3://motorhammer/carbayUploads/pub_img/
  CAR = "https://images.motorhammer.com/pub_img/image_placeholder.jpg"
}

export enum LegacyRoutePaths {
  CAR_DETAILS = "/car-details/",
  AUCTION_DETAILS = "/auction/"
}
