import { buildTextResources4Enum } from "@emibee/lib-app-common";
import { buildTextResourcesMH, Domains, enumNamespace } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: "TextResources",
  resources: {
    pageTitle: "Text Resources",
    routeTitle: "Text Resources",
    tooltipSettingsBtn: "Settings & Actions",
    selectViewLabel: "Text Resource View",
    selectLanguagesLabel: "Loaded Languages"
  }
});

export enum TextResourceView {
  masterDetail,
  detailList
}

export const TextResourceViewTextResources = buildTextResources4Enum<typeof TextResourceView>({
  scope: "Data",
  namespace: enumNamespace(Domains.textRes, "View"),
  resources: {
    masterDetail: "Master-Detail",
    detailList: "Detail List"
  }
});
