import { buildRouteWithParams, useClientService, useRoutingApi } from "@emibee/lib-app-common";
import React from "react";
import { SignInRoute } from "./SignInPage";
import { checkRedirectUrl } from "./common";

export const SignOutRoute = buildRouteWithParams<SignOutPageProps>({
  path: "/signout",
  resources: {
    component: SignOutPage
  },
  params: ["redirect"]
});

export interface SignOutPageProps {
  redirect?: string;
}

export default function SignOutPage(props: SignOutPageProps) {
  const { redirect } = props;

  const routingApi = useRoutingApi();
  const client = useClientService();

  React.useEffect(() => {
    client.signOut().then(() => {
      if (redirect) {
        window.location.replace(checkRedirectUrl(redirect));
      } else {
        routingApi.activateRoute(SignInRoute);
      }
    });
  });
  return null;
}
