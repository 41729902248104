import { buildTextResources4Data, dataKitBuilder } from "@emibee/lib-app-common";
import { GeoLocation, IDealer } from "@mh/common";

// export interface Dealer {
//   id: string;
//   name: string;
//   contactPerson: string;
//   dealerCode: string;
//   phone: string;
//   email: string;
//   additionalEmails: [string];
//   street: string;
//   city: string;
//   zip: string;
//   doesNotCollectFee: boolean;
//   preventBidsBelowStart: boolean;
//   dealerFee: number;
//   ftpID: string;
//   // _billingAccount: { type: Schema.Types.ObjectId, ref: 'BillingAccount' },
//   _blacklist: [UserMin];
//   keyAccount: string;
//   acquiredBy: string;
//   filterLabel: string;
//   createdAt: string;
//   statistics: DealerStatistics;
//   orgId: string;
// }

const dealerTextResources = buildTextResources4Data<IDealer>({
  scope: "Data",
  namespace: "Dealer",
  resources: {
    id: "ID",
    name: "Name",
    contactPerson: "ContactPerson",
    dealerCode: "DealerCode",
    phone: "Phone",
    email: "Email",
    additionalEmails: "AdditionalEmails",
    street: "Street",
    city: "City",
    zip: "Zip",
    state: "State",
    stateCode: "State Code",
    country: "Country",
    countryCode: "Country Code",
    _billingAccount: "Billing Account",
    doesNotCollectFee: "DoesNotCollectFee",
    preventBidsBelowStart: "PreventBidsBelowStart",
    dealerFee: "DealerFee",
    extraFee: "ExtraFee",
    ftpID: "FtpID",
    vatId: "Vat ID",
    taxId: "Tax ID",
    registrationNumber: "Registration No",
    _blacklist: "_blacklist",
    keyAccount: "KeyAccount",
    acquiredBy: "AcquiredBy",
    filterLabel: "FilterLabel",
    createdAt: "CreatedAt",
    orgId: "Organization ID",
    geoLocation: "GeoLocation",
    noExportCars: "no Export cars",
    canAccessLeadMgmt: "LeadMgmt",
    dekraOrgUnitID: "Dekra OrgUnit Id",
    dekraOrgUnitRootID: "Dekra OrgUnit Root ID",

    enableSingleAuction: "Single Auction",
    enableBundleAuction: "Bundle Auction",
    enableDutchAuction: "Dutch Auction",
    enableAdminAuction: "Prospecting "
  }
});

interface GetDealerDetailsArgs {
  dealerId: string;
}

interface GetDealerListArgs {
  maxResults?: number;
}

const LegacyGeoLocationTextResources = buildTextResources4Data<GeoLocation>({
  scope: "Data",
  namespace: "LegacyDealer",
  resources: {
    confirmed: "confirmed",
    formatted_address: "Address",
    location: "Location",
    multiple: "Multiple Hits"
  }
});
export const LegacyGeoLocationDataKit = dataKitBuilder(LegacyGeoLocationTextResources).build();

export const DealerDataKit = dataKitBuilder(dealerTextResources)
  // export const DealerDataKit = DealerStatisticsDataKit.extend(dealerTextResources)
  .field("geoLocation")
  .props({ complex: LegacyGeoLocationDataKit })
  .queries(qb => ({
    getDealerDetails: qb
      .query("getDealerDetails", { allowNull: true })
      .args<GetDealerDetailsArgs>("GetDealerDetailsInput")
      .Result.selectFields(
        "id",
        "name",
        "contactPerson",
        "dealerCode",
        "phone",
        "email",
        "street",
        "zip",
        "city",
        "createdAt",
        "acquiredBy",
        "keyAccount",
        "doesNotCollectFee"
      )
      // .Result.allFields(true)
      // .subFields("_blacklist", "id", "username", "email")
      // .subBuilder("statistics", builder => {
      //   builder.allFields(true);
      // })
      // .subBuilder("geoLocation", builder => {
      //   builder.selectFields("formatted_address");
      // })
      .build(),
    getDealerList: qb
      .query("getDealerList", { allowNull: true })
      .args<GetDealerListArgs>("GetDealerListInput")
      .ListResult.selectFields(
        "id",
        "name",
        "contactPerson",
        "dealerCode",
        "phone",
        "email",
        "street",
        "zip",
        "city",
        "createdAt",
        "acquiredBy",
        "keyAccount",
        "doesNotCollectFee"
      )
      // .subFields("_blacklist", "username", "email")
      .build()
  }))
  .build();
