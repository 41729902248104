import { TextResources, TextResourcesDefinition, buildTextResources } from "@emibee/lib-app-common";
// import { AccountSessionsNS, CarDetailsPageNS, CarListPageNS, AccountListPageNS, AccountDetailsPageNS } from "../views";

type ScopesT = "Pages" | "Fields" | "Core" | "Common" | "Controls" | "Views" | "Widgets" | "Data" | "Route" | "UI";

export function buildTextResourcesMH<R extends TextResourcesDefinition<ScopesT>, T extends {} = R["resources"]>(
  resourcesDef: R
): TextResources<T> {
  return buildTextResources<R, T>(resourcesDef);
}

export function pages(namespace: string) {
  return ["Pages." + namespace, core()];
}

export function widgets(namespace?: string) {
  return namespace ? "Widgets." + namespace : "Widgets";
}

export enum Domains {
  account = "Account",
  auction = "Auction",
  car = "Car",
  common = "Common",
  dealer = "Dealer",
  organization = "Organization",
  passwordInput = "PasswordInput",
  securityRole = "SecurityRole",
  task = "Task",
  notification = "Notification",
  textRes = "TextRes",
  assessment = "Assessment",
  ui = "UI"
}

export enum ViewNames {
  account = "Account",
  auction = "Auction",
  car = "Car",
  inbox = "Inbox",
  personalInfo = "PersonalInfo"
}

export function commonNamespace(scope: ScopesT) {
  return "Common." + scope;
}

export function enumNamespace(domain: Domains, nameOfEnum: string) {
  return `${domain}.Enum.${nameOfEnum}`;
}

// export function data(namespace?: "TextRes" | "Car" | "Account" | DataNames.dealer) {
export function data(domain?: Domains) {
  return domain ? "Data." + domain : "Data";
}

export function common(domain?: Domains) {
  return domain ? "Common." + domain : "Common.UI";
}

export function dataNamespace(domain: Domains, namespace: string) {
  return `${domain}.${namespace}`;
}

export function viewNamespace(viewName: ViewNames, namespace: string) {
  return `${viewName}.${namespace}`;
}

export function domainViewNamespace(domain: Domains, namespace: string) {
  return `${domain}.${namespace}`;
}

export function domainView(domain: Domains) {
  return ["Views." + domain, data(domain), common(domain)];
}
export function views(viewName?: ViewNames) {
  return [viewName ? "Views." + viewName : "Views"];
}

// export function fields(namespace: string) {
//   return "Fields." + namespace;
// }

export function core(namespace?: string) {
  return namespace ? "Core." + namespace : "Core";
}

const defaultsNs = [common(), "Core"];
export function defaults(domain?: Domains) {
  return domain ? [common(domain), ...defaultsNs] : defaultsNs;
}

export enum ControlNames {
  dataGrid = "DataGrid",
  fileRef = "FileRef",
  listOverflow = "ListOverflow",
  propertyTable = "PropertyTable",
  common = "Common"
}

export function controls(namespace?: ControlNames) {
  return namespace ? "Controls." + namespace : "Controls";
}
