import { buildSelectOptions, buildTextResources4Enum, TaskDataKitBuilder, TaskInfo } from "@emibee/lib-app-common";
import { DataManagementField, SignUpDocumentType, TaskCommonActionName, TaskNamesMH } from "@mh/common";
import { Domains, enumNamespace } from "../core/textResourceScopes";

export type TaskInfoMH<DetailsT = any> = TaskInfo<TaskNamesMH, DetailsT>;
export const TaskDataKit = TaskDataKitBuilder<TaskInfoMH>();

const SignUpDocumentTypeTextResources = buildTextResources4Enum<typeof SignUpDocumentType>({
  scope: "Data",
  namespace: enumNamespace(Domains.task, "DocumentType"),
  resources: {
    registration: "Business Registration or Certificate",
    vatId: "VAT ID"
    // passportFront: "Passport (Front)",
    // passportBack: "Passport (Back)"
  }
});

export const SignUpDocumentTypeOptions = buildSelectOptions(SignUpDocumentType, SignUpDocumentTypeTextResources);

export const DataManagementFieldTextResources = buildTextResources4Enum<typeof DataManagementField>({
  scope: "Data",
  namespace: "Task.Enum.DataManagementField",
  resources: {
    vatId: "VatID",
    street: "Street"
  }
});

export const CommonTaskActionTextResources = buildTextResources4Enum<typeof TaskCommonActionName>({
  scope: "Data",
  namespace: "Task.Enum.TaskCommonActionName",
  resources: {
    accept: "Accept",
    confirm: "Confirm",
    reject: "Reject",
    yes: "Yes",
    no: "No",
    ok: "OK"
  }
});
