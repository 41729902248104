import { CarImageSize, CarMediaFile, MediaTypes } from "@mh/common";
import Avatar from "@mui/material/Avatar";
import { environment } from "../core/environment";
import { PlaceholderImages } from "../data/common";

export function ImageCell(props: {
  image?: CarMediaFile[] | CarMediaFile;
  // image?: string;
  size?: number;
  width?: number;
  height?: number;
}) {
  const { image, width, height, size = 80 } = props;

  const mainImgFile = Array.isArray(image) ? image.find(img => img.mediaType === MediaTypes.image) : image;

  return (
    <Avatar sx={{ height: height ?? size, width: width ?? size, borderRadius: width ? "4px" : "50%" }}>
      <img alt="MainImage" src={getImageUrl(mainImgFile, CarImageSize.thumb)} title="image" height={height ?? size} />
    </Avatar>
  );
}

export function getImageUrl(mediaFile?: CarMediaFile, size = CarImageSize.medium) {
  if (mediaFile?.uri) {
    if (mediaFile.mediaType === MediaTypes.image && !mediaFile.v2) {
      // legacy
      if (size === CarImageSize.medium || size === CarImageSize.large || size === CarImageSize.orig) {
        // da wir nur 1 thumbnail in der Legacy generieren sollten wir f+r alles was größer ist als thumb/small das original nehmen
        return `${environment.imageUrl}/${mediaFile.uri}`;
      } else {
        const lastSlashIndex = mediaFile.uri.lastIndexOf("/");
        if (lastSlashIndex > 0) {
          return `${environment.imageUrl}/${mediaFile.uri.substring(0, lastSlashIndex)}/thumb_${mediaFile.uri.substring(
            lastSlashIndex + 1
          )}`;
        }
      }
    } else {
      const idx = mediaFile.uri.lastIndexOf(".");
      if (mediaFile.mediaType === MediaTypes.image && size !== CarImageSize.orig && idx > 0) {
        return `${environment.imageUrl}/${mediaFile.uri.substring(0, idx)}_thumb${size}${mediaFile.uri.substring(idx)}`;
      } else return `${environment.imageUrl}/${mediaFile.uri}`;
    }
  } else {
    return PlaceholderImages.CAR;
  }
}

export function appendTimestampToImageUrl(url: string) {
  return `${removeTimestampFromImageUrl(url)}?${Date.now()}`;
}

export function removeTimestampFromImageUrl(url: string) {
  const timestamp = url.lastIndexOf("?");
  return url.substring(0, timestamp > -1 ? timestamp : url.length);
}
