import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { RwfPermission, RwfPrivilege } from "@emibee/lib-shared";
import OrgIcon from "@mui/icons-material/CorporateFare";
import { ControlNames, controls, defaults, Domains, domainView } from "../../core/textResourceScopes";
import { OrganizationMH, OrganizationMHDataKit } from "../../data/organization";
import { textResources } from "./textResources";
import { OrganizationType } from "@mh/common";
import { KeepAliveOwner } from "../../core/MHClientService";

export enum OrganizationDetailsTab {
  details,
  actions,
  contacts,
  accounts,
  roles,
  subunits,
  files,
  audit
}

export interface OrganizationDetailsConcurrentProps extends PageConcurrentPrefetchProps<OrganizationMH> {
  organization?: OrganizationMH;
  orgId?: string;
  initTab?: OrganizationDetailsTab;
}

export type OrganizationListConcurrentProps = PageConcurrentPrefetchProps<OrganizationMH[]>;

export const OrganizationDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/orgDetails",
  displayText: textResources.routeDetailsText,
  icon: <OrgIcon />,
  auth: true,
  privilege: RwfPrivilege.Organization,
  permission: RwfPermission.full,
  resources: {
    lazy: () => import("./OrganizationDetailsPage"),
    localeNamespaces: [
      domainView(Domains.organization),
      domainView(Domains.account),
      controls(ControlNames.fileRef),
      defaults()
    ],
    prefetchGraph: (args?: OrganizationDetailsConcurrentProps) => ({
      query: OrganizationMHDataKit.queries.getOrganization,
      args: { id: args?.orgId },
      watchQuery: true
    })
  },
  params: ["orgId", "initTab"]
});

export const OrganizationListRoute = buildRouteWithGraphPrefetch({
  path: "/orgs",
  icon: <OrgIcon />,
  displayText: textResources.routeListText,
  auth: true,
  resources: {
    lazy: () => import("./OrganizationListPage"),
    localeNamespaces: [domainView(Domains.organization), defaults()],
    prefetchGraph: () => ({
      query: OrganizationMHDataKit.queries.getOrganizations,
      args: OrganizationMHDataKit.buildMultiSelectFetchMoreArgs(
        "orgType",
        [OrganizationType.dealer],
        OrganizationType,
        {}
      ),
      keepAlive: KeepAliveOwner.OrganizationList,
      cvFetch: true,
      watchQuery: true,
      mode: "allData"
    })
  },
  privilege: RwfPrivilege.Organization,
  permission: RwfPermission.full
});

// export const AccountSessionDetailsRoute = buildRouteWithGraphPrefetch({
//   path: "/sessionDetails",
//   icon: <SessionsIcon />,
//   displayText: textResources.routeAccountSessionDetails,
//   auth: true,
//   resources: {
//     lazy: () => import("./SessionDetailsPage"),
//     localeNamespaces: [views(ViewNames.accountSession)],
//     prefetchGraph: (args?: SessionDetailsConcurrentProps) => ({
//       query: SessionDataKit.queries.getSessions,
//       args: SessionDataKit.emptyFetchMoreArgs({ account: args?.accountId })
//     })
//   },
//   params: ["accountId"],
//   privilege: Privileges.ReadSessions
// });
