import {
  buildRouteWithGraphPrefetch,
  PageConcurrentPrefetchProps,
  SecurityRole,
  SecurityRoleDataKit
} from "@emibee/lib-app-common";
import { IAccount, IAccountSessionInfo, RwfPermission, RwfPrivilege } from "@emibee/lib-shared";
import RolesIcon from "@mui/icons-material/PeopleOutline";
import { defaults, Domains, domainView } from "../../core/textResourceScopes";
import { textResources } from "./textResources";

export interface SessionDetailsConcurrentProps extends PageConcurrentPrefetchProps<IAccountSessionInfo<IAccount>[]> {
  accountId?: string;
}

export type SecurityRoleConcurrentProps = PageConcurrentPrefetchProps<SecurityRole[]>;

export const SecurityRoleRoute = buildRouteWithGraphPrefetch({
  path: "/roles",
  icon: <RolesIcon />,
  displayText: textResources.routeText,
  auth: true,
  resources: {
    lazy: () => import("./SecurityRoleListPage"),
    localeNamespaces: [domainView(Domains.securityRole), defaults()],
    prefetchGraph: () => ({
      query: SecurityRoleDataKit.queries.getSecurityRoles,
      watchQuery: true
    })
  },
  privilege: RwfPrivilege.SecurityRoles,
  permission: RwfPermission.full
});

// export const AccountSessionDetailsRoute = buildRouteWithGraphPrefetch({
//   path: "/sessionDetails",
//   icon: <SessionsIcon />,
//   displayText: textResources.routeAccountSessionDetails,
//   auth: true,
//   resources: {
//     lazy: () => import("./SessionDetailsPage"),
//     localeNamespaces: [views(ViewNames.accountSession)],
//     prefetchGraph: (args?: SessionDetailsConcurrentProps) => ({
//       query: SessionDataKit.queries.getSessions,
//       args: SessionDataKit.emptyFetchMoreArgs({ account: args?.accountId })
//     })
//   },
//   params: ["accountId"],
//   privilege: Privileges.ReadSessions
// });
