import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import logo from "../logo2_p.svg";

import { makeStyles } from "../Theme";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  avatarInner: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    paddingLeft: 10,
    width: 64,
    height: 64
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,

    width: 76,
    height: 76
  },
  avatarSmall: {
    width: 48,
    height: 48
  },
  avatarInnerSmall: {
    width: 40,
    height: 40
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -38,
    marginLeft: -38
  },
  buttonProgressSamll: {
    marginTop: -24,
    marginLeft: -24
  }
}));

interface WaitIndicatorMHProps {
  className?: string;
  size?: "small" | "medium" | "large";
  value?: number;
}
export function WaitIndicatorMH(props: WaitIndicatorMHProps) {
  const { className, size = "medium", value } = props;
  const { classes, cx } = useStyles();

  let avatarSize: string | undefined;
  let avatarInnerSize: string | undefined;
  let buttonProgressSamll: string | undefined;
  let iconSize = 48;
  let circularSize = 76;
  switch (size) {
    case "small":
      avatarSize = classes.avatarSmall;
      avatarInnerSize = classes.avatarInnerSmall;
      buttonProgressSamll = classes.buttonProgressSamll;
      iconSize = 36;
      circularSize = 48;
      break;
  }

  return (
    //<div className={classes.wrapper}>
    <Avatar className={cx(classes.avatar, className, avatarSize)}>
      <Avatar className={cx(classes.avatarInner, avatarInnerSize)}>
        <img src={logo} className="App-logo" alt="logo" height={iconSize} width={iconSize} />
      </Avatar>
      <CircularProgress
        size={circularSize}
        variant={value !== undefined ? "determinate" : "indeterminate"}
        className={cx(classes.buttonProgress, buttonProgressSamll)}
        value={value}
      />
    </Avatar>

    //</div>
  );
}

const useStylesLoading = makeStyles()(theme => ({
  container: {
    paddingTop: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export function Loading() {
  const { classes } = useStylesLoading();

  return (
    <div className={classes.container}>
      <WaitIndicatorMH />
    </div>
  );
}
