import { ErrorType, ServicesContext, INotificationService, NotificationService } from "@emibee/lib-app-common";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withStyles } from "../Theme";
import * as React from "react";
import { IErrorBoundary } from "./NotificationService";

export const ErrorBoundaryContext = React.createContext<IErrorBoundary>({
  asyncError: (error: any) => console.error(error),
  triggerReload: () => {}
});
ErrorBoundaryContext.displayName = "ErrorBoundaryContext";

export const useErrorBoundary = () => {
  // const notifier = React.useContext(ErrorBoundaryContext).asyncError;
  // return notifier;
  return React.useContext(ErrorBoundaryContext);
};

const C_ErrorBoundary = "ErrorBoundary";
class ErrorBoundaryComponent extends React.Component<
  React.PropsWithChildren & { classes?: any },
  { error?: Error | string | null | ErrorType; reload?: boolean }
> {
  static contextType = ServicesContext;
  context!: React.ContextType<typeof ServicesContext>;
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(C_ErrorBoundary, error, errorInfo);
  }
  componentDidUpdate() {
    if (this.state.reload) {
      this.setState({ reload: undefined });
    }
  }

  reportError() {
    if (this.state.error) {
      const notS = this.context.accessor.get<INotificationService>(NotificationService);
      notS.reportIssue(this.state.error);
      this.setState({ error: null });
    }
  }

  asyncError(error: Error | string | ErrorType) {
    logger.error(C_ErrorBoundary, error);
    this.setState({ error });
  }

  triggerReload() {
    this.setState({ reload: true });
  }

  render() {
    const { error, reload } = this.state;
    if (reload) {
      return null;
    } else if (error !== undefined) {
      const { classes } = this.props;

      return (
        <div className={classes.root}>
          <Avatar className={classes.avatar}>
            <SentimentVeryDissatisfiedIcon fontSize="inherit" color="primary" />
          </Avatar>
          <Typography variant="h6">We are truly sorry, something went wrong.</Typography>
          <div className={classes.buttons}>
            <Button disabled={error === null} onClick={() => this.reportError()}>
              Report issue
            </Button>
            <Button color="secondary" onClick={() => this.setState({ error: undefined })}>
              Reload
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <ErrorBoundaryContext.Provider
          value={{ asyncError: this.asyncError.bind(this), triggerReload: this.triggerReload.bind(this) }}
        >
          {this.props.children}
        </ErrorBoundaryContext.Provider>
      );
    }
  }
}

export const ErrorBoundary = withStyles(ErrorBoundaryComponent, theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "20vh"
  },
  buttons: {
    marginTop: theme.spacing(3)
  },
  avatar: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    borderStyle: "solid",
    width: 72,
    height: 72,
    borderBottomWidth: "thick",
    fontSize: "4rem",
    marginBottom: theme.spacing(3)
  },
  text: {
    position: "absolute",
    top: "20%"
  }
}));
