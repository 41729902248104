import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const accountCommonTextResources = buildTextResourcesMH({
  scope: "Common",
  namespace: Domains.account,
  resources: {}
});

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.account,
  resources: {
    //AccountList

    routeListText: "Account List",
    routeDetailsText: "Account Details",

    //account Details

    pageTitle: "Account List",
    sectionOverview: "Account Overview",
    sectionTabDetails: "Details",
    sectionTabActions: "Actions",
    sectionTabFiles: "Files",
    sectionTabAuctions: "Auctions",
    sectionTabMemberships: "Memberships",
    sectionTabAudit: "Audit",
    sectionTabDetailsAccountSection: "Account",
    sectionTabDetailsAccountCompany: "Company",
    sectionTabActionsAccountTasks: "Account Tasks",
    sectionTabActionsAccountActions: "Account Actions",
    actionActivateAccountTitle: "Activate Account",
    actionActivateAccount: "activate",
    actionActivateAccountDescription: "Account is deactivated. Activate the account.",
    actionActivateAccountDescriptionSuccessToast: "Account was activated.",
    actionDectivateAccountDescriptionSuccessToast: "Account was deactivated.",
    actionDeactivateAccountTitle: "Deactivate Account",
    actionDeactivateAccount: "deactivate",
    actionDeactivateAccountDescription: "Deactivate Account. User cannot log in anymore until reactivated.",
    actionRecoverPwdTitle: "Reset Password",
    actionRecoverPwd: "Reset Password",
    actionRecoverPwdDescriptionSuccessToast:
      "Password reset successfull. Please check your eMails for the password recover link.",

    actionDeleteAccountTitle: "Delete Account",
    actionDeleteAccount: "Delete",
    actionDeleteAccountDescription:
      "Delete Account. User can not login anymore. Account will be permanently deleted after 30 days.",
    actionDeleteAccountQuery:
      "Deleted Accounts cannot be recovered. Only continue if you are certain, that you want to delete the Account!",
    actionDeleteAccountSuccessToast: "Account successfully deleted.",

    actionResendVerificationEmailTitle: "Resend eMail verification link",
    actionResendVerificationEmail: "Resend",
    actionResendVerificationEmailDescription:
      "Resend eMail verification link. Do so, if user cannot find the original verification email.",
    actionResendVerificationEmailQuery: "Do you want to resend the eMail verification link?",
    actionResendVerificationEmailSuccessToast: "Verification eMail successfully send.",

    sectionTabFilterSettings: "Filter Settings",
    sectionTabFilterVintage: "Filter Vintage",
    sectionTabFilterPrice: "Filter Price",
    sectionTabFilterMileage: "Filter Mileage",
    sectionTabFilterMakerModel: "Filter Maker/Model",
    sectionTabFilterDealers: "Filter Dealers",
    sectionTabFilterTaxDeductable: "Filter Tax",
    sectionTabFilterDetails: "Filter Misc",

    changeDateRange: "Change Date Range",

    sectionTabNotificationSettings: "Notification Settings",

    actionRecoverPwdDescription: "The user forgot his password. Send him a password recovery link.",
    linkAccountSessions: "Show",

    contactInfoDialogTitle: "Contact Info",
    accountInfoDialogTitle: "Account Info",
    accountNotFound: "Account not found",
    noActiveTasksFound: "No active tasks.",
    accountDetailsLinkText: "Details",

    sectionTabActionsAuthorizeForDealers: "Authorize for Dealers",
    actionAuthorizeForDealersTitle: "Authorize for Dealers",
    actionAuthorizeForDealers: "Authorize for Dealers",
    actionAuthorizeForDealersDescription: "Authorize User for Dealers",
    actionAuthorizeForDealersTitleSuccessToast: "Authorized for successfully updated",

    actionPremiumUserTitle: "User is Premium User",
    actionNormalUserTitle: "Upgrade to Premium User",
    actionUpgradePremiumUserButton: "Upgrade",
    actionDowngradePremiumUserButton: "Downgrade",
    actionQueryPremiumUserDowngrade: "Do you really want to downgrade the User?",

    actionV2OnlyUserTitle: "User is V2 only User",
    actionLegacyUserTitle: "Upgrade to V2 only User",
    actionV2OnlyUserDescription: "User is fully migrated and cannot access the Legacy Plattform.",
    actionLegacyUserDescription: "User is not fully migrated and can access the Legacy Plattform",
    actionSetV2UserButton: "Set V2 only",
    actionAllowLegacyButton: "Allow Legacy",
    actionQueryLegacyUser: "Do you really want to downgrade the User?",

    // Account Edit

    actionEditAccountTooltip: "Edit Account",
    dialogEditTitle: "Edit Account",
    organizationPickText: "Choose Organization",

    // Account Session

    pageTitleAccountSession: "Account Sessions",
    routeAccountSessions: "Sessions",
    routeAccountSessionDetails: "Session Details",
    sectionSessionData: "Account Session",
    sectionLogData: "Logging Session",
    sectionActions: "Actions",
    sectionAllSessions: "All Account Sessions",
    btnLoadSessions: "Load all sessions",
    actionTerminateTitle: "Terminate Session",
    actionTerminate: "Terminate",
    actionTerminateDescription: "Terminate the session. This cannot be undone.",
    actionTerminateQuery: "Do You really want to terminate the session? (This cannot be undone)",
    actionTerminateAllTitle: "Terminate all sessions",
    actionTerminateAll: "Terminate all",
    actionTerminateAllDescription: "Terminate all active or online sessions. This cannot be undone.",
    actionTerminateAllQuery:
      "Do You really want to terminate all sessions of the selected account? (This cannot be undone)",
    actionTerminateSuccessToast: "All session terminated.",
    noSessionsFound: "No Sessions found...",

    // custom reason dialog
    titleCustomReason: "Disable Account",
    stateReason: "Disable Reason",
    btnCancel: "Cancel",
    btnDisable: "Submit"
  }
});
