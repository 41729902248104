import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps, SessionDataKit } from "@emibee/lib-app-common";
import SessionsIcon from "@mui/icons-material/RecentActors";

import { AccountSessionState, IAccount, IAccountSessionInfo, RwfPermission, RwfPrivilege } from "@emibee/lib-shared";
import { Domains, domainView } from "../../core/textResourceScopes";
import { textResources } from "../accountDetails/textResources";

export interface SessionDetailsConcurrentProps extends PageConcurrentPrefetchProps<IAccountSessionInfo<IAccount>[]> {
  accountId?: string;
}

export type SessionListConcurrentProps = PageConcurrentPrefetchProps<IAccountSessionInfo<IAccount>[]>;

export const AccountSessionsRoute = buildRouteWithGraphPrefetch({
  path: "/sessions",
  icon: <SessionsIcon />,
  displayText: textResources.routeAccountSessions,
  auth: true,
  resources: {
    lazy: () => import("./SessionListPage"),
    localeNamespaces: [domainView(Domains.account)],
    prefetchGraph: () => ({
      query: SessionDataKit.queries.getSessions,
      args: SessionDataKit.buildMultiSelectFetchMoreArgs(
        "vState",
        [AccountSessionState.active, AccountSessionState.online],
        AccountSessionState,
        {}
      ),
      watchQuery: true,
      policy: "network-only"
    })
  },
  privilege: RwfPrivilege.Session,
  permission: RwfPermission.full
});

export const AccountSessionDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/sessionDetails",
  icon: <SessionsIcon />,
  displayText: textResources.routeAccountSessionDetails,
  auth: true,
  resources: {
    lazy: () => import("./SessionDetailsPage"),
    localeNamespaces: [domainView(Domains.account)],
    prefetchGraph: (args?: SessionDetailsConcurrentProps) => ({
      query: SessionDataKit.queries.getSessions,
      args: SessionDataKit.emptyFetchMoreArgs({ account: args?.accountId })
    })
  },
  params: ["accountId"],
  privilege: RwfPrivilege.Session,
  permission: RwfPermission.full
});
