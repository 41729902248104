import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import { RwfPermission, RwfPrivilege, TaskState } from "@emibee/lib-shared";
import TaskIcon from "@mui/icons-material/Assignment";
import { defaults, Domains, domainView } from "../../core/textResourceScopes";
import { TaskDataKit, TaskInfoMH } from "../../data/task";
import { textResources } from "./textResources";

export interface TaskDetailsConcurrentProps extends PageConcurrentPrefetchProps<TaskInfoMH> {
  taskId?: string;
}
export type TaskListConcurrentProps = PageConcurrentPrefetchProps<TaskInfoMH[]>;

export const TaskDetailsRoute = buildRouteWithGraphPrefetch({
  path: "/taskDetails",
  displayText: textResources.taskDetailsTitle,
  icon: <TaskIcon />,
  auth: true,
  privilege: RwfPrivilege.Task,
  permission: RwfPermission.full,
  resources: {
    lazy: () => import("./TaskDetailsPage"),
    localeNamespaces: [domainView(Domains.task), defaults()],
    prefetchGraph: (args?: TaskDetailsConcurrentProps) => ({
      query: TaskDataKit.queries.getTask,
      watchQuery: true,
      args: { id: args?.taskId }
    })
  },
  params: ["taskId"]
});

export const TaskListRoute = buildRouteWithGraphPrefetch({
  path: "/taskList",
  displayText: textResources.taskListTitle,
  icon: <TaskIcon />,
  auth: true,
  privilege: RwfPrivilege.Task,
  permission: RwfPermission.full,
  resources: {
    lazy: () => import("./TaskListPage"),
    localeNamespaces: [domainView(Domains.task), defaults()],
    prefetchGraph: () => ({
      query: TaskDataKit.queries.getTasks,
      args: TaskDataKit.buildMultiSelectFetchMoreArgs("state", [TaskState.created], TaskState, {}),
      watchQuery: true
    })
  }
});
