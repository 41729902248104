import { GraphFeature, useConstant } from "@emibee/lib-app-common";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  TextField
} from "@mui/material";
import { differenceInMilliseconds, isValid, startOfToday } from "date-fns";
import * as React from "react";
import { DataGridProfile, MHClientService } from "../../core/MHClientService";
import { DataGridViewItem } from "../../core/common";
import { useMHClient } from "../MHClientProvider";
import { DataGridApi, DataGridApiPro, DataGridFilterItem, DataGridInitialState } from "../dataGrid/DataGrid";
import { cloneDeep } from "@apollo/client/utilities";

export function useDataGridViews(props: {
  dataGridKey?: string;
  dataGridApi: React.MutableRefObject<DataGridApiPro>;
  loading?: boolean;
}) {
  const [selectedView, setSelectedView] = React.useState<DataGridViewItem>();
  const { dataGridKey, ...rest } = props;
  const component = dataGridKey ? (
    <DataGridViewPicker
      key="dgView"
      dataGridKey={dataGridKey}
      {...rest}
      selectedView={selectedView}
      onSelected={setSelectedView}
    />
  ) : (
    undefined
  );

  return {
    component
  };
}

const DefaultView: DataGridViewItem = {
  id: 0,
  label: "Default",
  relFilter: true
};

interface DataGridViewPickerState {
  editItem?: DataGridViewItem;
  views: DataGridViewItem[];
}
interface DataGridViewPickerStateAction {
  command: "menuItem" | "save" | "edit" | "cancel" | "delete" | "add";
  item: DataGridViewItem;
  items?: DataGridViewItem[];
}

function DataGridViewPickerReducer(
  state: DataGridViewPickerState,
  action: DataGridViewPickerStateAction
): DataGridViewPickerState {
  switch (action.command) {
    case "edit":
    case "add":
      return { ...state, editItem: action.item };
    case "save":
      return { ...state, editItem: undefined, views: action.items ?? [] };
    case "delete":
      return { ...state, views: action.items ?? [] };
    case "cancel": {
      return { ...state, editItem: undefined };
    }
    default:
      return state;
  }
}

function DataGridViewPicker({
  dataGridKey,
  dataGridApi,
  loading,
  selectedView,
  onSelected
}: {
  dataGridKey: string;
  dataGridApi: React.MutableRefObject<DataGridApiPro>;
  loading?: boolean;
  selectedView?: DataGridViewItem;
  onSelected: (view: DataGridViewItem) => void;
}) {
  const [anchorEl, setAnchor] = React.useState<HTMLElement>();
  const [restored, setRestored] = React.useState(false);

  const client = useMHClient();
  const profileData = React.useMemo(() => client.profileService.getScope("datagrid")?.[dataGridKey], [
    client,
    dataGridKey
  ]);
  const defaultView = useConstant(() => ({ ...DefaultView }));

  const selItem = selectedView ?? defaultView;

  const [state, dispatcher] = React.useReducer(DataGridViewPickerReducer, {
    views: profileData?.views ?? []
  });

  const loadingEffect = restored ? false : loading;
  React.useEffect(() => {
    if (!defaultView.data) {
      defaultView.data = cloneDeep(exportDgState(dataGridApi, true));
    }
    // Hint: we have a few issues here
    // 1. Default View with implicit fetchmore triggers 2 backend requests (register + fetchmore)
    // 2. Loading flag would triggers multiple restores, so we just consider unitl restored

    if (selectedView) {
      restoreDgState(dataGridApi, selectedView);
      setRestored(true);
    } else if (!loadingEffect) {
      const selView = profileData?.views?.find(p => p.isDefault);
      if (selView) {
        // setDefaultPending(true);
        onSelected(selView);
      }
    }
  }, [selectedView, onSelected, profileData, defaultView, dataGridApi, loadingEffect]);

  const idCounterRef = React.useRef(-1);
  if (idCounterRef.current === -1) {
    idCounterRef.current = state.views.reduce((max, cur) => Math.max(max, cur.id), 0);
  }

  const handleClose = () => {
    setAnchor(undefined);
  };

  const handleCommand = (command: DataGridViewPickerStateAction["command"], item: DataGridViewItem) => {
    let items = state.views;

    switch (command) {
      case "menuItem":
        handleClose();
        onSelected(item);
        break;
      case "cancel":
        dispatcher({ command, item });
        handleClose();
        break;
      case "add":
        dispatcher({ command, item: { id: -1, label: "", relFilter: true } });
        break;
      case "save":
        item.data = exportDgState(dataGridApi, item.relFilter);

        if (item.id === -1) {
          item.id = ++idCounterRef.current;
          items = [...state.views, item];
          dispatcher({ command: "menuItem", item });
        }

        if (item.isDefault) {
          items.forEach(view => {
            if (view !== item) {
              view.isDefault = undefined;
            }
          });
        }

        dispatcher({ command, item, items });
        mergeProfileData({ ...profileData, views: items }, dataGridKey, client);
        onSelected(item);
        handleClose();
        break;
      case "edit":
        dispatcher({ command, item });

        break;
      case "delete":
        items = state.views.filter(view => view !== item);
        dispatcher({ command, item, items });
        mergeProfileData({ ...profileData, views: items }, dataGridKey, client);

        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button startIcon={<TableViewIcon />} onClick={ev => setAnchor(ev.currentTarget)}>
        {selItem.label}
      </Button>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={handleClose}
        slotProps={{ paper: { sx: { width: 356 } } }}
      >
        <DatagridViewMenuItem allowAdd selected={selItem === defaultView} view={defaultView} onAction={handleCommand} />
        {state.views.length ? <Divider sx={{ flexGrow: 1 }} /> : undefined}
        {state.views.map(view => (
          <DatagridViewMenuItem
            key={view.id}
            view={view}
            selected={selItem === view}
            allowEdit
            allowDel
            onAction={handleCommand}
          />
        ))}
      </Menu>
      {state.editItem && <EditDatagridViewDialog view={state.editItem} onAction={handleCommand} />}
    </>
  );
}
function DatagridViewMenuItem({
  view,
  onAction,
  selected,
  allowAdd,
  allowEdit,
  allowDel,
  allowDefault
}: {
  view: DataGridViewItem;
  selected?: boolean;
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowDel?: boolean;
  allowDefault?: boolean;
  onAction: (action: DataGridViewPickerStateAction["command"], item: DataGridViewItem) => void;
}) {
  return (
    <Box ml={1} mr={3} display="flex" alignItems="center">
      {selected ? <CheckIcon /> : view.isDefault && allowDefault ? <StarOutlineIcon /> : <Box ml={3} />}

      <MenuItem disabled={selected} sx={{ height: 40, flexGrow: 1 }} onClick={() => onAction("menuItem", view)}>
        {view.label}
      </MenuItem>

      <Box minWidth={112} gap={1} display="flex" flexDirection="row-reverse">
        {allowAdd && (
          <IconButton onClick={() => onAction("add", view)} color="secondary" edge="end">
            <AddIcon />
          </IconButton>
        )}
        {allowDel && !selected && (
          <IconButton onClick={() => onAction("delete", view)} edge="end">
            <DeleteIcon />
          </IconButton>
        )}
        {allowEdit && (
          <IconButton onClick={() => onAction("edit", view)} edge="end">
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

function EditDatagridViewDialog({
  view,

  onAction
}: {
  view: DataGridViewItem;
  onAction: (action: "cancel" | "save", item: DataGridViewItem) => void;
}) {
  const [label, setLabel] = React.useState(view.label);
  const [isDefault, setIsDefault] = React.useState(view.isDefault ?? false);
  const [relFilter, setRelFilter] = React.useState(view.relFilter);

  const handleSave = () => {
    view.label = label;
    view.isDefault = isDefault;
    view.relFilter = relFilter;
    onAction("save", view);
  };

  return (
    <Dialog open>
      <DialogTitle>Datagrid View</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" gap={3}>
          <TextField
            size="small"
            autoFocus
            value={label ?? ""}
            onChange={ev => setLabel(ev.target.value)}
            label="Name"
          />
          <FormControlLabel
            control={<Checkbox checked={isDefault} onChange={(_, checked) => setIsDefault(checked)} />}
            label="Default"
          />
        </Box>
      </DialogContent>
      <DialogContent dividers>
        <FormControlLabel
          control={<Switch checked={relFilter} onChange={(_, checked) => setRelFilter(checked)} />}
          label="Save Filter relative"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAction("cancel", view)}>Cancel</Button>
        <Button disabled={!label} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function mergeProfileData(profile: DataGridProfile, dgKey: string, client: MHClientService) {
  const dgProfiles = client.profileService.getScope("datagrid") ?? {};
  dgProfiles[dgKey] = profile;
  client.profileService.setScope("datagrid", dgProfiles);
}

type DateFilterDiffValue = DataGridFilterItem & { diffMs: number };
function isDateFilterDiffValue(value: unknown): value is DateFilterDiffValue {
  return typeof value === "object" && typeof (value as DateFilterDiffValue).diffMs === "number";
}
function exportDgState(dataGridApi: React.MutableRefObject<DataGridApiPro>, applyRelativeFilter?: boolean) {
  const state = dataGridApi.current.exportState();
  if (applyRelativeFilter && state?.filter?.filterModel) {
    const now = startOfToday();
    state.filter.filterModel.items.forEach(filter => {
      if (isValid(filter.value)) {
        const filterDate = new Date(filter.value + ".000");

        if (filter.value && isValid(filterDate)) {
          // console.log(
          //   "exportDgState",
          //   filter.value,
          //   filterDate.toISOString(),
          //   new Date(now).toISOString(),
          //   differenceInMilliseconds(filterDate, now)
          // );
          (filter as DateFilterDiffValue).diffMs = differenceInMilliseconds(filterDate, now);
        }
      }
    });
  }
  return state;
}

function restoreDgState(dataGridApi: React.MutableRefObject<DataGridApiPro>, view: DataGridViewItem) {
  const state = view.data as DataGridInitialState;
  if (state) {
    if (view.relFilter && state?.filter?.filterModel) {
      const now = startOfToday();

      state.filter.filterModel.items.forEach(filter => {
        if (isDateFilterDiffValue(filter)) {
          const filterDate = new Date(now.getTime() + filter.diffMs - now.getTimezoneOffset() * 60000).toISOString();
          // console.log("importDgState", now.getTimezoneOffset(), filterDate);
          filter.value = filterDate;
        }
      });
    }
    dataGridApi.current.restoreState(state);
  }
}
