import {
  ClientException,
  CommonResponse,
  ErrorType,
  IServicesAccessor,
  newErrorType,
  NotificationService
} from "@emibee/lib-app-common";
import { AnyObject } from "final-form";
import { BehaviorSubject } from "rxjs";
import { BlockScreenHandle, IErrorBoundary, INotificationServiceMH } from "./NotificationService";

function debounce<R>(f: (...args: any[]) => R, interval: number) {
  let timer: any = null;
  let promise: Promise<R> | undefined = undefined;

  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
      promise = undefined;
    }

    const newPromise = () =>
      new Promise<R>(resolve => {
        timer = setTimeout(() => {
          timer = null;
          return resolve(f(...args));
        }, interval);
      });
    promise = promise ? promise.then(() => newPromise()) : newPromise();
    return promise;
  };
}

type TransactionTask<R = boolean | undefined | void> = () => R | Promise<R>;
type RollbackHandler<T> = (t: Transaction<T>) => void;
export interface LifeCycleData {
  id: any;
  [key: string]: any;
}
export type LifeCycleUpdater = (prevData: AnyObject, data?: AnyObject, property?: string[]) => void;

export interface Transaction<T> {
  id: number;
  payload: T;
  setTask: (task: TransactionTask, boundaryRefresh?: () => void) => void;
  //rollback: () => void;
  //commit: () => void;
}

class AutoCommitTransaction<T> implements Transaction<T> {
  private _task?: TransactionTask;

  constructor(
    readonly id: number,
    readonly payload: T,
    private _onRollback: RollbackHandler<T>,
    private _tm: TransactionManager<T>,
    readonly undoId?: number
  ) {}

  public setTask(task: TransactionTask, boundaryRefresh?: () => void) {
    this._task = task;
    this._tm.triggerExecution(boundaryRefresh);
  }

  public get canExecute() {
    return this._task !== undefined;
  }
  public async execute() {
    return this._task && this._task();
  }
  public rollback() {
    return this._onRollback(this);
  }
}
export class ExclusiveTransaction {
  private _executingPromise: Promise<any> | undefined;
  constructor(
    public readonly name: string,
    private readonly _updateLifeCycle: LifeCycleUpdater,
    private _tm: TransactionManager<any>
  ) {}

  public setTask(task: TransactionTask<CommonResponse<any>>, errorBoundary?: IErrorBoundary) {
    this._executingPromise = this._tm.executeSingleQuery(task, this.name, true, errorBoundary);
    return this._executingPromise;
  }

  public updateLifeCycle<T = void>(id: any, data?: LifeCycleData, property?: Extract<keyof T, string>[]) {
    this._updateLifeCycle(id, data, property);
  }

  public get executionMonitor() {
    return this._executingPromise;
  }
}

type TaskExecutionResult<T> = T & {
  error?: ErrorType;
  decided?: boolean;
  tryCount: number;
};

export interface TransactionManagerChangeArgs {
  dirty: boolean;
}

const C_TransactionManager = "TransactionManager";
export class TransactionManager<T> {
  private _id = 0;
  private _exclusiveTransPending = false;
  private _queue: AutoCommitTransaction<T>[] = [];
  private _execDebouncer = debounce(this._debouncedExecution.bind(this), 500);
  private _notificationService_: INotificationServiceMH | undefined;
  private _subject = new BehaviorSubject<boolean>(false);
  private static _RETRY_ATTEMPTS = 3;
  private static _RETRY_INTERVAL_MS = 3000;

  constructor(private _servicesAccessor: IServicesAccessor) {}

  private get _notificationService() {
    if (!this._notificationService_) {
      this._notificationService_ = this._servicesAccessor.get(NotificationService) as INotificationServiceMH;
    }
    return this._notificationService_;
  }

  public observeChanges(observer: (ars: TransactionManagerChangeArgs) => void) {
    console.log("TransactionManager.observeChanges");
    return this._subject.subscribe({
      next: state => observer({ dirty: state })
    });
  }

  private _notifyDirty() {
    this._subject.next(this._queue.length > 0);
  }

  public enqueue(payload: T, rollbackHandler: RollbackHandler<T>, undoId?: number): Transaction<T> | undefined {
    if (this._exclusiveTransPending)
      throw new ClientException("Cannot enqueue transaction: single transaction is still running.");

    console.log("Transaction.enqueue", undoId);
    const lastTrans = this._queue.length > 0 ? this._queue[0] : undefined;
    if (undoId !== undefined && (!lastTrans || lastTrans.undoId !== undoId)) {
      const trans = this._buildTransaction(payload, rollbackHandler, undoId);
      this._queue.unshift(trans);

      console.log("Transaction enqueued", payload, lastTrans && lastTrans.undoId, undoId);
      this._notifyDirty();
      return trans;
    } else {
      this._queue.shift();
      this._notifyDirty();
      console.log("Transaction removed due to matching undoId");
    }
  }

  private static async _executeTask<T, R = T>(
    task: TransactionTask<T>,
    notificationService: INotificationServiceMH,
    notifyIfNoRetry = true,
    boundaryRefresh?: () => void,
    blockScreen?: BlockScreenHandle,
    resultTransformer?: (result: T) => R
  ): Promise<TaskExecutionResult<R>> {
    let retry = 1;
    let retryCount = 0;
    let closeHandle;
    do {
      const tryCount = retryCount - (retry - 1) + 1;
      try {
        const result = await task();
        const finalResult = resultTransformer ? resultTransformer(result) : result;
        if (closeHandle) closeHandle.close();
        // if (result === false) {
        //     this.rollback(trans);
        //     if (closeHandle) closeHandle.close();
        //     this._notificationService.error("Save operation aborted. Changes will be rolled back.");
        //     breakLoop = true;
        //     break;
        // }

        return { ...finalResult, tryCount } as TaskExecutionResult<R>;
      } catch (error) {
        let ex: ClientException;
        if (error instanceof ClientException) {
          ex = error;
        } else {
          ex = new ClientException(error as any);
        }

        retry--;
        if (retry < 1) {
          let stopRetry = false;
          if (closeHandle) {
            // already retried
            closeHandle.close();

            const continueRetry = await closeHandle.queryRetry();
            if (!continueRetry) {
              stopRetry = true;
              closeHandle = undefined;
            }
          }
          let result;
          if (!stopRetry) {
            result = await notificationService.errorCheckDecision(
              ex,
              TransactionManager._RETRY_ATTEMPTS,
              tryCount,
              notifyIfNoRetry,
              boundaryRefresh,
              blockScreen
            );
            if (result && typeof result !== "boolean") closeHandle = result;
          }

          if (closeHandle) {
            retry = TransactionManager._RETRY_ATTEMPTS;
            retryCount += retry;
            closeHandle.progress(retry);
          } else {
            //this.rollback(trans);
            //this._notificationService.error("Save operation aborted. Changes will be rolled back.");
            return {
              error: ex.errorType,
              tryCount,
              decided: result as boolean
            } as TaskExecutionResult<R>;
          }
        } else if (closeHandle) {
          closeHandle.progress(retry);
        }
        // wait
        await new Promise<void>(resolve => setTimeout(() => resolve(), 3000));
      }
    } while (retry > 0);

    throw new Error("unreachable");
  }

  public requestExclusiveTransaction(taskName: string, updateLifeCycle: LifeCycleUpdater) {
    if (this._queue.length > 0) throw new ClientException("Cannot execute exclusive transaction: queue is not empty.");
    if (this._exclusiveTransPending)
      throw new ClientException(
        "Cannot execute exclusive transaction: Another exclusive transaction is still running."
      );

    this._exclusiveTransPending = true;

    return new ExclusiveTransaction(taskName, updateLifeCycle, this);
  }

  public executeSingleQuery<T extends CommonResponse<any>>(
    task: TransactionTask<T>,
    taskName: string,
    exclusive: boolean,
    errorBoundary?: IErrorBoundary
  ): Promise<T | undefined> {
    return this._executeSingle<T>(task, taskName, exclusive, errorBoundary, result => {
      if (result.error) throw new ClientException(result.error);
      return result;
    }).then(resp => {
      if (resp && resp.error) {
        return {
          error: newErrorType(resp.error)
        } as T;
      } else return resp as T;
    });
  }

  private _executeSingle<T, R = T>(
    task: TransactionTask<T>,
    taskName: string,
    exclusive: boolean,
    errorBoundary?: IErrorBoundary,
    resultTransformer?: (result: T) => R
  ) {
    const blockScreenHandle = this._notificationService.blockScreen(900);
    const promise = TransactionManager._executeTask(
      task,
      this._notificationService,
      false,
      errorBoundary && errorBoundary.triggerReload,
      blockScreenHandle,
      resultTransformer
    )
      .then(result => {
        if (!result.error) {
          if (result.tryCount > 1) {
            logger.warn(
              C_TransactionManager,
              `Task '${taskName}' successfully comleted after ${result.tryCount} tries.`
            );
          }
          return result;
        } else {
          const error = result.error ? result.error : "ExecuteSingle returned neither a result nor an error.";
          if (!result.decided) {
            if (result.tryCount > 1) {
              logger.error(C_TransactionManager, `Task '${taskName}' failed after ${result.tryCount} tries.`, error);
            }
            if (result.decided === undefined) {
              if (result.tryCount < 2 && errorBoundary) errorBoundary.asyncError(error);
              else {
                this._notificationService.error(error);
              }
              return { error };
            }
          }
        }
      })
      .finally(() => {
        if (exclusive) this._exclusiveTransPending = false;
      });

    blockScreenHandle.setPromise(promise);
    return promise;
  }

  private _buildTransaction(payload: T, rollbackHandler: RollbackHandler<T>, undoId?: number) {
    return new AutoCommitTransaction<T>(++this._id, payload, rollbackHandler, this, undoId);
  }

  // private _dequeueTrans(id: number) {
  //     const t = this._queue.pop();
  //     if (!t)
  //         throw new Error("Invalid state: No transaction to commit.");
  //     if (t.id !== id) {
  //         this._queue.push(t);
  //         throw new Error(`Invalid operation: Cannot commit transaction because it is not first in queue.`);
  //     }
  //     return t;
  // }

  private _peekTrans() {
    return this._queue.length > 0 && this._queue[this._queue.length - 1];
  }

  private _dequeueTrans() {
    const t = this._queue.pop();
    this._notifyDirty();
    return t;
  }

  private async _debouncedExecution(boundaryRefresh?: () => void) {
    let trans: AutoCommitTransaction<T> | undefined = undefined;
    console.log("Transaction.execution starting...");
    do {
      // no retrigger happend
      trans = this._dequeueTrans();
      if (trans && trans.canExecute) {
        console.log("Transaction.execution started", trans);
        const taskName = "unknown";
        const result = await TransactionManager._executeTask(
          trans.execute.bind(trans),
          this._notificationService,
          undefined,
          boundaryRefresh
        );
        if (result === false || result.error) {
          if (!result.decided) {
            this.rollback(trans);
          }
          if (result.decided === undefined) {
            if (result.error) {
              logger.error(
                C_TransactionManager,
                `Task '${taskName}' failed after ${result.tryCount} attempt(s).`,
                result.error
              );
            }
            this._notificationService.error("Save operation aborted. Changes will be rolled back.");
          }
        } else {
          if (result.tryCount > 1) {
            logger.warn(
              C_TransactionManager,
              `Task '${taskName}' successfully comleted after ${result.tryCount} attempt(s).`
            );
          }
          this._notificationService.success("Data succesfully saved.");
        }
      } else {
        console.log("Transaction.execution left", trans);
        //this._notificationService.error(new Error("Invalid state: No transaction to be executed."));
        // release handle
        trans = undefined;
      }
    } while (trans);

    console.log("Transaction.execution ended");
  }

  public triggerExecution(boundaryRefresh?: () => void) {
    const t = this._peekTrans();
    console.log("Transaction.triggerExecution.enter", t);

    if (t && t.canExecute) {
      this._execDebouncer(boundaryRefresh);
    }
    //     // stop execution
    //     const prevHandleWhenStarted = this._prevExecHandleId = this._execHandleId;

    //     // prevent execution
    //     if (this._execHandleId) {
    //         clearTimeout(this._execHandleId);
    //         if (this._execResolver !== undefined) {
    //             this._execResolver(false);
    //             this._execResolver(false);
    //             this._execResolver = undefined;
    //         }
    //         console.log("Transaction.triggerExecution.clearTimeout");
    //     }
    //     const me = this;

    //     this._execPromise = this._execPromise.then( () => {
    //         return new Promise<boolean>( resolve => {
    //             this._execHandleId = setTimeout( () => resolve(true),5000);
    //             this._execResolver = resolve;
    //         });
    //     }).then( val => {
    //         if (val) {

    //         }
    //         return undefined;
    //     });

    //     const promise = new Promise<boolean>( resolve => );
    //     promise.

    //     this._execHandleId = setTimeout(async () => {
    //         console.log("Transaction.triggerExecution.setTimeout started");
    //         , 5000);
    // }
  }

  private rollback(transaction: AutoCommitTransaction<T>) {
    // rollback from latest to provided
    let trans: AutoCommitTransaction<T> | undefined = transaction;
    this._queue.push(transaction);
    while ((trans = this._queue.shift())) {
      try {
        trans.rollback();
      } catch (error) {
        console.error("Transaction rollback failed:", trans.id, error);
        this._notificationService.error(error as any);
        break;
      }
    }
    this._notifyDirty();
  }
}
