"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactRole = exports.OrganizationType = exports.ApprovalStatus = exports.OrgInviteContext = void 0;
var OrgInviteContext;
(function (OrgInviteContext) {
    OrgInviteContext["contact"] = "contact";
})(OrgInviteContext || (exports.OrgInviteContext = OrgInviteContext = {}));
var ApprovalStatus;
(function (ApprovalStatus) {
    ApprovalStatus[ApprovalStatus["rejected"] = -1] = "rejected";
    ApprovalStatus[ApprovalStatus["pending"] = 0] = "pending";
    ApprovalStatus[ApprovalStatus["approved"] = 1] = "approved";
})(ApprovalStatus || (exports.ApprovalStatus = ApprovalStatus = {}));
var OrganizationType;
(function (OrganizationType) {
    OrganizationType[OrganizationType["other"] = 0] = "other";
    OrganizationType[OrganizationType["dealer"] = 50] = "dealer";
    OrganizationType[OrganizationType["reseller"] = 10] = "reseller";
})(OrganizationType || (exports.OrganizationType = OrganizationType = {}));
var ContactRole;
(function (ContactRole) {
    ContactRole[ContactRole["primaryContact"] = 1] = "primaryContact";
    ContactRole[ContactRole["billingAddressee"] = 2] = "billingAddressee";
})(ContactRole || (exports.ContactRole = ContactRole = {}));
