import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.auction,
  resources: {
    salesListRouteText: "Sales List",
    auctionListRouteText: "Auction List",
    endedAuctionListRouteText: "Ended Auctions",
    // routeListText: "Auction Details",
    legacyAuctionDetails: "Legacy Details",
    detailsPageTitle: "Auction Details",
    // routeDetailsText: "Auction Details",
    listPageTitle: "Auctions",
    bidTabTitle: "Bids",

    sectionAuctionInfo: "Info",
    sectionTabAuctionDetails: "Auction Details",
    sectionTabDetails: "Details",
    sectionTabActions: "Actions",
    sectionTabBids: "Bids",
    sectionTabVisitors: "Visitors",
    sectionTabStarred: "Starred",

    carDetailsLink: "Car Details",
    orgDetailsLink: "Organization",

    sectionTabAuctionReminders: "Reminders to Reseller",
    actionSendPickupReminderTitle: "Pickup Reminder",
    actionSendPickupReminderAction: "send Reminder",
    actionSendPickupReminderDescription: "Send a pickup reminder to the buyer.",

    actionSendContractReminderTitle: "Contract Reminder",
    actionSendContractReminderAction: "send Reminder",
    actionSendContractReminderDescription: "Send a contract reminder to the buyer.",

    actionSendPaymentReminderTitle: "Payment Reminder",
    actionSendPaymentReminderAction: "Send Reminder",
    actionSendPaymentReminderDescription: "Send a payment reminder to the buyer.",
    auctionReminderSentAt: "Reminder sent at {{dateStr}}",

    sectionTabShareAuction: "Share Auction",
    actionShareViaEmail: "Share Auction link via eMail",
    actionShareViaEmailAction: "Share via eMail",
    actionShareViaWhatsApp: "Share Auction link via WhatsApp",
    actionShareViaWhatsAppAction: "Share via WhatsApp",

    shareEmailLinkText: "Besuchen Sie die Auktion unter:\n{{url}}",
    shareWhatsAppLinkText: "Schauen Sie sich diese Auction bei MotorHammer an: {{url}}",

    sectionTabAuctionDealerReminders: "Reminders to Dealership",
    actionDealerSendDocumentsReminderTitle: "Documents Reminder",
    actionDealerSendDocumentsReminderAction: "Send Reminder",
    actionDealerSendDocumentsReminderDescription: "Remind dealer to send required documents to the buyer.",

    actionRequestAuctionStatusReminderTitle: "Request Auction Status",
    actionRequestAuctionStatusReminderAction: "Request Status",
    actionRequestAuctionStatusReminderDescription: "Request the auction/prospecting status from the dealer.",

    actionGenerateVoucherTitle: "Generate Voucher",
    actionGenerateVoucherAction: "Generate Voucher",
    actionGenerateVoucherDescription: "Generate a prefilled Voucher in the Legacy App.",

    sectionTabAuctionActions: "Status Actions",
    // todo: wie kann man ohne localize parametrisieren? (wenn ein TextResourceKey zurückgegeben werden soll)
    actionProspectStatusTitle: "Prospect Status is {status === true ? 'Deal' : status === false ? 'NoDeal' : 'open'}",
    actionProspectStatusTitleDeal: {
      fallback: "Prospect Status is <strong>Deal</strong>",
      trans: {}
    },
    actionProspectStatusTitleNoDeal: {
      fallback: "Prospect Status is <strong>No Deal</strong>",
      trans: {}
    },
    actionProspectStatusTitleOpen: {
      fallback: "Prospect Status is <strong>open</strong>",
      trans: {}
    },
    queryChangeProspectStatus: "Do you really want to change the Prospect Status?",

    sendEmailSuccessToast: "Email send successfully!",

    actionSetDealTitle: "Set Auction to Deal",
    actionSetDealAction: "Deal",
    actionSetDealDescription: "Set Prospect Status to Deal",

    actionSetNoDealTitle: "Set Auction to No Deal",
    actionSetNoDealAction: "No Deal",
    actionSetNoDealDescription: "Set Prospect Status to No Deal",

    actionLegacyCloneCarTitle: "Clone Car (back to Car List)",
    actionLegacyCloneCarDescription:
      "Clone Car, so that it appears in the Car List. This action is only available, if the Status is 'No Deal'!",
    actionLegacyCloneCarAction: "Clone Car",
    actionLegacyCloneCarSuccessToast: "Car was successfully cloned. You will be redirected shortly.",

    actionRetriggerAuctionMailTitle: "Retrigger Auction Mail",
    actionRetriggerAuctionMailAction: "Trigger Mail",
    actionRetriggerAuctionMailSuccessToast: "Auction Mail was retriggered successfully",
    actionRetriggerAuctionMailDescription:
      "Retrigger Auction Mail. Prospectings will be send instantly, the rest according to the Mail Schedule",
    sectionTabAdminActions: "Admin Actions",
    sectionTabDelete: "Delete",
    actionDeleteAuction: "Delete Auction",
    actionDeleteAuctionDescription: "Permanentelly delete an Auction",
    actionDeleteAuctionConfirm: "Do you really want to delete the Auction? This action cannot be undone!",

    sectionTabFees: "Pricing",
    motorhammerCollectsFees: "MotorHammer collects fee",
    organizationCollectsFees: "Organization collects fee",
    auctionFeeNet: "Auction Fee (Net)",
    auctionFeeGross: "Auction Fee (Gross)",
    dealerFee: "Organization Fee",
    extraFee: "Extra Fee",
    total: "Total",
    tax: "Tax",

    manuallySetBidTitle: "Manually Set Bid",
    manuallySetBidDescription: "You can set a bid manually",
    manuallySetBidAction: "Set Bid",

    tooltipHighestBid: "Highest Bid",
    tooltipStartPrice: "Start Price",
    tooltipTargetPrice: "Target Price",
    tooltipPriceRange: "Price Range",
    tooltipReservePrice: "Reserve Price"
  }
});
