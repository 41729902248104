import * as React from "react";
//import { ApolloProvider } from '@apollo/react-hooks';
//import { ChangeScope, MHClientService, ObservedChanges } from '../core/MHClientService';
import { DataStoreContext, SimpleDataStore, IDataStore, UndoManager, UndoManagerContext } from "../core/dataManagement";
//import { ServicesContext, useServices } from './ServicesProvider';
//import { NotificationProvider } from './NotificationProvider';
import { useServices, useClientService } from "@emibee/lib-app-common";
import { MHClientService } from "../core/MHClientService";
import { RwfPermission, RwfPrivilege, SpecialPrivilege } from "@emibee/lib-shared";
import { SpecialPrivilegeMH } from "@mh/common";

// export interface MHClientContextData {
//     client: MHClientService;
// }

// export const MHClientContext = React.createContext<MHClientContextData>({} as any);
// MHClientContext.displayName = "MHClientContext";

export function useMHClient() {
  return useClientService() as MHClientService;
}

export function useAuthorization(
  privilege: RwfPrivilege | SpecialPrivilege | SpecialPrivilegeMH,
  permission?: RwfPermission
) {
  const mhClient = useMHClient();
  // solange wir beide Security.modelle fahren, muss die permission gesetzt sein, um auf v2 zu gehen
  return React.useMemo(() => mhClient.authorize(privilege, permission), [mhClient, privilege, permission]);
}

// export const useMHClientObserver = (...scope: ChangeScope[]): [MHClientService, ObservedChanges | undefined] => {
//     const mhClient = useMHClient();
//     const [state, setState] = React.useState<ObservedChanges>();

//     React.useEffect(() => {
//         console.log("MHClient.useEffect");
//         const sub = mhClient.observeChanges((args) => {
//             console.log("MHClient.observeChanges", args);
//             setState(args);
//         }, ...scope);
//         return () => {
//             console.log("MHClient.unsubscribe")
//             sub.unsubscribe();
//         }

//     },[setState, mhClient, ...scope]);

//     return [mhClient, state];
// }

// interface MHClientProps {
//     service: MHClientService;
// }
// export const MHClientProvider:React.FC<MHClientProps> = (props) => {
//     console.log("MHClient.Render");

//     return (
//         <ServicesContext.Provider value={{accessor: props.service}}>
//             <NotificationProvider />
//             <ApolloProvider client={props.service.apollo}>
//                 {props.children}
//             </ApolloProvider>
//         </ServicesContext.Provider>
//     );
// }

interface DataStoreProviderProps {
  undo?: boolean | UndoManager;
  store?: IDataStore;
  children: React.ReactNode;
}
export function DataStoreProvider(props: DataStoreProviderProps) {
  console.log("DataStoreProvider.Render");
  const accessor = useServices();
  const [dataStore] = React.useState(props.store || new SimpleDataStore(accessor));

  return (
    <DataStoreContext.Provider value={{ dataStore }}>
      <UndoProvider
        dataStore={props.undo ? dataStore : undefined}
        undoManager={typeof props.undo !== "boolean" ? props.undo : undefined}
      >
        {props.children}
      </UndoProvider>
    </DataStoreContext.Provider>
  );
}

interface UndoProviderProps {
  dataStore?: IDataStore;
  undoManager?: UndoManager;
  children: React.ReactNode;
}
function UndoProvider(props: UndoProviderProps) {
  console.log("UndoProvider.Render");
  const [undoManager] = React.useState(() =>
    props.undoManager ? props.undoManager : props.dataStore ? new UndoManager(props.dataStore) : undefined
  );

  // dispose on unmount
  React.useEffect(() => {
    return () => undoManager && undoManager.dispose();
  }, [undoManager]);

  return <UndoManagerContext.Provider value={{ undoManager }}>{props.children}</UndoManagerContext.Provider>;
}
