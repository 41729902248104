import { Link } from "@emibee/lib-app-common";
import React from "react";
import { buildTextResourcesMH } from "../../core/textResourceScopes";

export const AccountPages = "Account";
export const AccountPages_SignIn = "Account.SignIn";
export const AccountPages_SignUp = "Account.SignUp";
export const AccountPages_ForgotPwd = "Account.ForgotPassword";
export const AccountPages_RecoverPwd = "Account.RecoverPassword";
export const AccountPages_SignUp_EmailVerify = "Account.SignUp_EmailVerify";
export const AccountPages_SignUp_EmailChange = "Account.SignUp_EmailChange";
export const AccountPages_SignUp_Reseller = "Account.SignUp_Reseller";
export const AccountPages_SignUp_Dealer = "Account.SignUp_Dealer";
export const AccountPages_2ndF_EmailVerify = "Account.2ndF_EmailVerify";
export const AccountPages_2ndF_Info = "Account.2ndF_Info";

export const CommonTextResources = buildTextResourcesMH({
  scope: "Pages",
  namespace: AccountPages,
  resources: {
    usernamePattern:
      "{{field}} may only contain alphanumeric characters or single hyphens, and cannot begin or end with an hyphen.",
    signIn: "Already have an account? Sign in",
    formSignUpSubmitButton: "Sign Up",
    nextButton: "Next",
    prevButton: "Prev",
    acceptPrivacy: {
      fallback: "<0>I agree to our <1>Privacy Policy</1></0>",
      trans: (
        <>
          I agree to our
          <Link color="secondary" target={"_blank"} href="https://www.motorhammer.com/legal/privacy">
            Privacy Policy
          </Link>
        </>
      )
    },
    acceptConditions: {
      fallback: "<0>I agree to our <1>Terms of Service</1>, our <3>Prices</3> and <5>Hammer Guide</5></0>",
      trans: (
        <>
          I agree to our
          <Link color="secondary" target={"_blank"} href="https://www.motorhammer.com/legal/agb">
            Terms of Service
          </Link>
          , our
          <Link color="secondary" target={"_blank"} href="https://www.motorhammer.com/legal/pricing">
            Prices
          </Link>
          and
          <Link color="secondary" target={"_blank"} href="https://www.motorhammer.com/howto">
            Hammer Guide
          </Link>
        </>
      )
    },
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    passwordMismatchMessage: "Passwords do not match.",
    linkResendVerificationMail: "Resend verification email?",
    setNewPasswordSuccessMessage: "Password successfully set. You will be redirected automatically.",
    setNewPasswordPendingMessage: "Your token is being verified…",
    btnSubmitSetNewPassword: "Set Password"
  }
});
