/**
 * UserDetails
 */
import {
  AccountContractTextResources,
  buildGraphCrudQuerySet,
  buildSelectOptions,
  buildTextResources4Data,
  dataKitBuilder,
  PrivilegedUnit,
  PrivilegedUnitDataKit
} from "@emibee/lib-app-common";
import { DealerDataKit } from "../dealer/Dealer";
import { UserInfo, UserInfoDataKit } from "./UserInfo";
import { accidentTypeTextResources, checkbookTypeTextResources, fuelTypeTextResources } from "../car";
import { FuelType, CheckbookType, AccidentType, IDealer } from "@mh/common";

import { Domains, dataNamespace } from "../../core/textResourceScopes";
import { ChangeAccountArgs, GetAccountArgs, IAccountContract } from "@emibee/lib-shared";

export interface UserDetails extends UserInfo, IAccountContract {
  extPrivUnits?: PrivilegedUnit[];
  fullName: string;
  affiliateCode: string;
  filterSettings?: FilterSettings;
  notificationSettings?: NotificationSettings;
  _authorizedFor: IDealer[];
}

const useDetailsTextResources = UserInfoDataKit.extendTextResources<UserDetails>({
  ...AccountContractTextResources,
  fullName: "Name",
  // affiliateCode: "Affiliate Code",
  filterSettings: "Filter Settings",
  notificationSettings: "Notification Settings",
  _authorizedFor: "Authorized For"
});

export interface NotificationSettings {
  // enableWhatsapp: boolean;
  // whatsappConfirmed: boolean;
  newAuctionEmailNotifications: boolean;
  newProspectingEmailNotifications: boolean;
  outbidEmailNotifications: boolean;
  // newAuctionWhatsappNotifications: boolean;
  // newProspectingWhatsappNotifications: boolean;
  // outbidWhatsappNotifications: boolean;
}

const notificationSettingsTextResources = buildTextResources4Data<NotificationSettings>({
  scope: "Data",
  namespace: dataNamespace(Domains.account, "NotificationSettings"),
  resources: {
    newAuctionEmailNotifications: "New Auction Notification",
    newProspectingEmailNotifications: "New Prospecting Notification",
    outbidEmailNotifications: "Outbid Notification"
  }
});

export interface FilterSettings {
  filterMaker: boolean;
  filterModel: boolean;

  filterKm: boolean;
  minKm: number;
  maxKm: number;

  filterPrice: boolean;
  minPrice: number;
  maxPrice: number;

  filterTaxDeductible: boolean;
  filterCanBeExported: boolean;

  filterDealer: boolean;
  selectedMakers: [string];
  selectedModels: [string];

  temp_taxDeductibleValue?: boolean;
  selectedDealers?: IDealer[];

  filterVintage: boolean;
  minVintage: string;
  maxVintage: string;

  filterDetails: boolean;
  temp_filterCheckbook: CheckbookType[];
  temp_filterAccident: AccidentType[];
  temp_filterFuelType: FuelType[];
}

const filterSettingsTextResources = buildTextResources4Data<FilterSettings>({
  scope: "Data",
  namespace: dataNamespace(Domains.account, "FilterSettings"),
  resources: {
    selectedMakers: "Selected Makers",
    selectedModels: "Selected Models",
    temp_taxDeductibleValue: "Tax deductible",
    filterModel: "Filter Model",
    filterMaker: "Filter Maker",
    filterCanBeExported: "Can Be Exported",
    filterDealer: "Filter Dealer",
    filterDetails: "Filter Details",
    filterPrice: "Filter Price",
    filterTaxDeductible: "Filter Tax Deductible",
    filterKm: "Filter Mileage",
    maxKm: "Max Mileage",
    minKm: "Min Mileage",
    maxPrice: "Max Price",
    minPrice: "Min Price",
    selectedDealers: "Selected Dealers",
    filterVintage: "Filter Vintage",
    minVintage: "Min Vintage",
    maxVintage: "Max Vintage",
    temp_filterCheckbook: "Filter Checkbook",
    temp_filterAccident: "Filter Accident",
    temp_filterFuelType: "Filter Fueltype"
  }
});

const FilterSettingsDataKit = dataKitBuilder(filterSettingsTextResources)
  .field("temp_filterFuelType")
  .props({
    options: buildSelectOptions(FuelType, fuelTypeTextResources)
  })
  .field("temp_filterCheckbook")
  .props({
    options: buildSelectOptions(CheckbookType, checkbookTypeTextResources)
  })
  .field("temp_filterAccident")
  .props({
    options: buildSelectOptions(AccidentType, accidentTypeTextResources)
  })
  // .field("temp_taxDeductibleValue")
  // .props({
  //   // options: buildSelectOptions(TaxDeductibleValues, taxDeductibleValuesTextResources)
  // })
  // .field("selectedDealers")
  // .props({ complex: DealerDataKit })
  .field("selectedDealers")
  .props({ complex: DealerDataKit })
  // .virtualField("milageRange")
  // .props({
  //   valueGetter: row => (row ? `${row.minKm} - ${row.maxKm}` : "-")
  // })
  .build();

const NotificationSettingsDataKit = dataKitBuilder(notificationSettingsTextResources).build();

export interface ActivateAccountArgs {
  accountId: string;
  activate: boolean;
  stateReason?: string;
}

export interface DeleteAccountArgs {
  accountId: string;
}

export interface UpdateAccountLegacyArgs {
  // account: Partial<UserDetails>;
  id?: String;
  _authorizedFor?: IDealer[] | String[];
  isPremiumUser?: Boolean;
  v2?: Boolean;
}

export const UserDetailsDataKit = UserInfoDataKit.extend(useDetailsTextResources)
  .virtualField("fullName")
  .props({
    valueGetter: row => (row.firstname ? `${row.firstname} ${row.lastname}` : row.lastname)
  })
  .field("extPrivUnits")
  .props({ complex: PrivilegedUnitDataKit })
  .field("filterSettings")
  .props({ complex: FilterSettingsDataKit })
  .field("notificationSettings")
  .props({ complex: NotificationSettingsDataKit })
  .field("_authorizedFor")
  .props({ complex: DealerDataKit })
  .queries(qb => ({
    getAccount: qb
      .query("getAccount")
      .args<GetAccountArgs>("GetAccountInput", true)

      .Result.allFields(true)
      .subBuilder("_authorizedFor", builder =>
        builder
          .customType<Partial<IDealer>>()
          // .selectFields("id")
          .selectFields("id", "name", "contactPerson", "dealerCode", "phone", "email", "street", "zip", "city")
      )
      .subBuilder("filterSettings", builder => builder.allFields(true).subFields("selectedDealers", "name"))
      .subBuilder("extPrivUnits", builder =>
        builder
          .selectFields("id", "ownerOrgId", "roles", "ownerOrg")
          .subFields("roles", "name")
          .subFields("ownerOrg", "name")
      )
      .build(),
    activateAccount: qb
      .mutation("activateAccount")
      .args<ActivateAccountArgs>("ActivateAccountInput", true)
      // .Result.allFields(true)
      .Result.selectFields("id", "username", "deactivated", "state")
      .build(),
    deleteAccount: qb
      .mutation("deleteAccount")
      .args<DeleteAccountArgs>("DeleteAccountInput", true)
      .Result.selectFields("username", "deactivated", "status")
      .build(),
    changeAccount: qb
      .mutation("changeAccount")
      .args<ChangeAccountArgs>("ChangeAccountInput", true)
      .Result.selectFields("id", "cid", "ownerOrgId", "phone")
      .subFields("ownerOrg", "name")
      .build(),
    updateAccountLegacy: qb
      .mutation("updateAccountLegacy")
      .args<Partial<UserDetails>>("UpdateAccountLegacyInput", true)
      .extractArgFields(
        "id",
        "firstname",
        "lastname",
        "title",
        "email",
        "phone",
        "_authorizedFor",
        "isPremiumUser",
        "v2"
      )
      .Result.selectFields("username", "deactivated", "status", "isPremiumUser", "v2")
      .subBuilder("_authorizedFor", builder =>
        builder.customType<Partial<IDealer>>().selectFields("id", "name", "dealerCode")
      )
      .build()
  }))
  .build();

export const UserDetailsGraphQuerySet = buildGraphCrudQuerySet(
  UserDetailsDataKit.queries.getAccount,
  UserDetailsDataKit.queries.changeAccount
);
