import { RouteDefinition } from "@emibee/lib-app-common";
import { RwfPermission } from "@emibee/lib-shared";
import { RwfPrivilegeMH } from "@mh/common";
import TextResIcon from "@mui/icons-material/Translate";
import { Privileges } from "../../core/authorization";
import { ControlNames, controls, data, Domains } from "../../core/textResourceScopes";
import { textResources } from "./textResources";

export const TextResourcesRoute: RouteDefinition = {
  path: "/textRes",
  icon: <TextResIcon />,
  displayText: textResources.routeTitle,
  auth: true,
  resources: {
    lazy: () => import("./TextResourcesPage"),
    localeNamespaces: [data(Domains.textRes), controls(ControlNames.dataGrid)]
  },
  // privilege: Privileges.AuthorTextResources
  privilege: RwfPrivilegeMH.TextResources,
  permission: RwfPermission.full
};
