import { PageConcurrentResources, RouteDefinition } from "@emibee/lib-app-common";
import { RwfPermission } from "@emibee/lib-shared";
import { RwfPrivilegeMH } from "@mh/common";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Domains,
  ViewNames,
  data,
  dataNamespace,
  defaults,
  domainView,
  pages,
  views,
  widgets
} from "../core/textResourceScopes";
import { CommonTextResources } from "./textResources";

export const AppConcurrent: PageConcurrentResources = {
  lazy: () => import("../App")
};

// export const LegacyRoute: RouteDefinition = {
//          path: `${environment.legacyUrl}/main`,
//          displayText: commonTextResources.linkLegacy,
//          icon: <LinkIcon />,
//          resources: {
//            lazy: () => import("../App")
//          }
//        };
export const DashboardRoute: RouteDefinition = {
  path: "/",
  icon: <DashboardIcon />,
  displayText: CommonTextResources.routeListText,
  auth: true,
  fallback: true,
  resources: {
    lazy: () => import("../pages/DashboardPage"),
    localeNamespaces: [
      widgets(),
      data(),
      domainView(Domains.notification),
      views(ViewNames.personalInfo),
      domainView(Domains.task),
      domainView(Domains.car),
      domainView(Domains.auction),
      dataNamespace(Domains.ui, "Formatters"),
      pages("Common"),
      defaults()
    ]
  }
};

export const AdminEnvironmentModelRoute: RouteDefinition = {
  path: "/envmodel",
  auth: true,
  resources: {
    lazy: () => import("../pages/AdminEnvironmentModelPage")
  },
  // privilege: Privileges.WriteEnvironmentModel
  privilege: RwfPrivilegeMH.Administration,
  permission: RwfPermission.full
};

export const AdminEnvironmentsRoute: RouteDefinition = {
  path: "/envs",
  auth: true,
  resources: {
    lazy: () => import("../pages/AdminEnvironmentsPage")
  },
  // privilege: Privileges.WriteEnvironment
  privilege: RwfPrivilegeMH.Administration,
  permission: RwfPermission.full
};

export const AdminConfigsRoute: RouteDefinition = {
  path: "/configs",
  auth: true,
  resources: {
    lazy: () => import("../pages/AdminConfigsPage")
  },
  // privilege: Privileges.WriteConfiguration
  privilege: RwfPrivilegeMH.Administration,
  permission: RwfPermission.full
};
