import addMinutes from "date-fns/addMinutes";
import eod from "date-fns/endOfDay";
import eom from "date-fns/endOfMonth";
import sod from "date-fns/startOfDay";
import som from "date-fns/startOfMonth";
import { environment } from "../core/environment";
import { IAccount } from "@emibee/lib-shared";
import React from "react";
import { OrganizationMH } from "../data/organization";
import { MinimalOrganization } from "../controls/domains/OrganizationMembershipSelect";

export function copyToClipboard(content?: string) {
  navigator.clipboard.writeText(content || "");
  return new Promise(resolve => resolve(true));
}

export function parsePageParameter<T extends number | undefined>(val: T): T | undefined {
  return val !== undefined ? (parseInt(val as any) as T) ?? undefined : undefined;
}

export function endOfDay(date: Date, gmt?: boolean) {
  const dateEod = eod(date);
  return gmt ? localToGmt(dateEod) : dateEod;
}

export function startOfDay(date: Date, gmt?: boolean) {
  const dateSod = sod(date);
  return gmt ? localToGmt(dateSod) : dateSod;
}

export function startOfMonth(date: Date, gmt?: boolean) {
  const dateSom = som(date);
  return gmt ? localToGmt(dateSom) : dateSom;
}

export function endOfMonth(date: Date, gmt?: boolean) {
  const dateEom = eom(date);
  return gmt ? localToGmt(dateEom) : dateEom;
}

export function localToGmt(localTime: Date) {
  return addMinutes(localTime, localTime.getTimezoneOffset() * -1);
}

export function gmtToLocal(gmtTime: Date) {
  return addMinutes(gmtTime, gmtTime.getTimezoneOffset());
}

export function dateSorter<T extends object>(property: keyof T, direction: "ASC" | "DESC"): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const valA = a[property];
    const valB = b[property];
    if (valA && valB) {
      if (direction === "DESC") {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      } else {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      }
    } else {
      return 0;
    }
  };
}

export function getComplexPathFF<T>(property: keyof T, path?: string) {
  if (path) {
    return `${path}.${String(property)}`;
  } else {
    return property;
  }
}

export function joinPath(str1: string, str2: string) {
  if (str1.endsWith("/")) {
    return `${str1}${str2 || ""}`;
  } else {
    return `${str1}/${str2 || ""}`;
  }
}

export function getEnumValues(obj: object, type: "number" | "string") {
  if (type === "string") {
    return Object.entries(obj).filter(key => isNaN(Number(key)));
  } else {
    return Object.entries(obj).filter(key => !isNaN(Number(key)));
  }
}

export function getEnumKeys(obj: object, type: "number" | "string"): string[] {
  if (type === "string") {
    const returnVal = Object.keys(obj).filter(key => isNaN(Number(key)));
    return returnVal;
  } else {
    const returnVal = Object.keys(obj).filter(key => !isNaN(Number(key)));
    return returnVal;
  }
}
export function isCompleteEnum<T extends object>(e: T, keys: (keyof T)[]): boolean {
  const enumKeys = Object.keys(e).filter(key => isNaN(Number(key))) as (keyof T)[];
  const missingKeys = enumKeys.filter(key => !keys.includes(key));
  if (missingKeys.length > 0) {
    console.error("Missing enum keys:", missingKeys);
    return false;
  }
  return true;
}

export function isTouchDevice(): boolean {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
}

export function getFileUrl(url: string) {
  const returnUrl = `${environment.fileUrl}/${url}`;
  return returnUrl;
}

const zxcvbn = require("zxcvbn");
export function buildPasswordScoring(account: Partial<IAccount>) {
  const banned = ["motorhammer"];
  if (account.email) banned.push(account.email);
  if (account.name) banned.push(account.name);
  return (pwd: string): number | undefined => {
    return pwd ? zxcvbn(pwd, banned)?.score : undefined;
  };
}

export function stringifyOrg(org: OrganizationMH | MinimalOrganization) {
  return org.displayName ?? `${org.name} ${org.branch ? "- " + org.branch : ""}`; // ? `${org.name} (${org.displayName})` : org.name;
}
