import { RoutingService } from "@emibee/lib-app-common";
import { AppRoutes } from "../common/AppRoutes";
import { LocalizationService } from "./LocalizationService";
import { MHClientService } from "./MHClientService";
import { environment } from "./environment";

export function setupClient() {
  const mhClient = new MHClientService(environment.graphServerUrl, environment.graphServerSubscriptionUrl);
  const rs = new RoutingService({ maxTrackingItems: 10 });
  // first register for security
  mhClient.registerService(rs);

  // register the routes
  rs.registerRoute(...AppRoutes);
  mhClient.registerService(new LocalizationService(mhClient));

  // add global subscriptions

  return mhClient;
}
