import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.dealer,
  resources: {
    listPageTitle: "Dealer List",
    detailsPageTitle: "Dealer Details",
    sectionDetails: "Dealer Info",
    sectionContact: "Contact",
    sectionAddress: "Address",
    dealerInfoDialogTitle: "Dealer Info",
    dealerNotFound: "Dealer not found",

    sectionTabAuctionStats: "Auction Stats",
    sectionTabAuctionList: "Auction List",
    sectionTabDealerAccountList: "Dealer Accounts",
    sectionTabDealerBlacklist: "Blacklist",
    auctionStatsTotal: "Auction Stats",
    auctionStatsSingle: "Single Auctions",
    auctionStatsBundle: "Bundle Auctions",
    auctionStatsDutch: "Prospectings",
    auctionStatsProspecting: "Admin Auctions",
    auctionStatsCars: "Cars",

    changeDateRange: "Select Date Range",
    dealerPickerTitle: "Select Dealer",
    dealerPickerAcceptBtn: "Save",
    dealerPickerSelectAll: "Select All",
    dealerPickerDeselectAll: "Deselect All"
  }
});
