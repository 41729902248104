import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.securityRole,
  resources: {
    pageTitle: "Security Roles",
    routeText: "Security Roles",
    btnTextNew: "New Security Role",
    btnTooltipEditProperties: "Edit Properties",
    btnTooltipEditPrivileges: "Edit Privileges",

    sectionRoleDetails: "Role Details",

    sectionPrivileges: "Privileges",
    dialogCreateTitle: "New Security Role",
    dialogEditTitle: "Change Security Role",
    dialogEditPrivilegesTitle: "Edit Privileges",

    // Action - Delete Role
    actionDeleteRoleSuccessToast: "Security Role successfully removed.",
    actionDeleteRoleQueryTitle: "Remove Security Role",
    actionDeleteRoleQueryText: "Remove Security Role and all its references?"
  }
});
