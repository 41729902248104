"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionAccountListTypes = exports.SecurityRole = exports.UserType = exports.UserStatus = exports.PersonalTitle = void 0;
var PersonalTitle;
(function (PersonalTitle) {
    PersonalTitle[PersonalTitle["Mr"] = 0] = "Mr";
    PersonalTitle[PersonalTitle["Ms"] = 1] = "Ms";
})(PersonalTitle || (exports.PersonalTitle = PersonalTitle = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["Rejected"] = 0] = "Rejected";
    UserStatus[UserStatus["Pending"] = 1] = "Pending";
    UserStatus[UserStatus["Approved"] = 2] = "Approved";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
var UserType;
(function (UserType) {
    UserType[UserType["System_Admin"] = 100] = "System_Admin";
    UserType[UserType["Admin"] = 80] = "Admin";
    UserType[UserType["Dealer"] = 50] = "Dealer";
    UserType[UserType["Reseller"] = 10] = "Reseller";
})(UserType || (exports.UserType = UserType = {}));
var SecurityRole;
(function (SecurityRole) {
    SecurityRole["User"] = "User";
    SecurityRole["Administrator"] = "Administrator";
    SecurityRole["SysAdmin"] = "SysAdmin";
})(SecurityRole || (exports.SecurityRole = SecurityRole = {}));
var AuctionAccountListTypes;
(function (AuctionAccountListTypes) {
    AuctionAccountListTypes[AuctionAccountListTypes["visitors"] = 0] = "visitors";
    AuctionAccountListTypes[AuctionAccountListTypes["starred"] = 1] = "starred";
})(AuctionAccountListTypes || (exports.AuctionAccountListTypes = AuctionAccountListTypes = {}));
